<template>
  <b-card
    no-body
    class="table-responsive"
  >
    <b-table
      :fields="fields"
      :items="companyTable"
      responsive
      class="table"
    >
      <!-- Institution -->
      <template>
        <div class="d-flex align-items-center">   
          <div>
            <div class="font-weight-bolder text-muted">
             {{this.companyTable['Holder']}} 
            </div>
          </div>
        </div>
      </template>

      <!-- Ownership -->
      <template >
        <div class="d-flex align-items-center text-muted">
          
          <span>{{ this.companyTable['% Out'] }}</span>
        </div>
      </template>

      <!-- Shares -->
      <template >
        <div class="d-flex flex-colum text-success">
          <span class="font-weight-bolder mb-25 text-success"
          >
          {{ companyTable.Shares}}</span>
        </div>
      </template>

      <!-- Value -->
      <template>
        <div class="text-success" >
          {{ this.companyTable.Value+'$' }}
        </div>
      </template>

      <!-- sales -->
      <template>
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1 text-muted">
            {{ this.companyTable.Reported}}
          </span>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
  },
  computed: {
    joke() {
     return this.$store.getters.getCurrentCompdata;
    }
  },
  watch:{
    joke( newJoke,oldJoke){
      this.companyTable = newJoke.Instituion
      this.hallo = newJoke.Instituion 
    }
  },
  data() {
    return {
      fields: [
        { key: 'Holder', label: 'Institution'},
        { key: '% Out', label: '%-Ownership'},
        { key: 'Shares', label: 'Shares'},
        { key: 'Value', label: 'Value'},
        { key: 'Date Reported', label: 'Reported'},
      ],
      companyTable:[]
      
    }

  },
  methods:{
  formatter(value, row, index, field) {
  if (value > 999) {
    value = value/1000
  return value + 'k';
  }
}
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
