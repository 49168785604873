<template>
  <b-card
    id="SimpleValue"
    no-body
    class="card-revenue-budget align-items-stretch"
    v-if="this.joke"
    
  >
    <b-row class="mx-0" style="margin: 10; padding: 0; height: 100%;"> <!-- Set height to 100% -->
      <b-col class="revenue-report-wrapper" style="margin: 0; padding: 0; height: 100%;"> <!-- Set height to 100% -->
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h2 class="font-weight-bold" style="margin-left: 30px; margin-top: 20px;">
            {{this.ticker}}
          </h2>
          <!-- <h5 class="card-subtitle float-left"> Changes</h5> -->
          <div class="d-flex align-items-center">
            <!-- <div class="d-flex align-items-center mr-2">
              <span style="margin-top: 15px; margin-left: 15px" class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
              <span style="margin-right: 5px;margin-top: 15px;">Value</span>
            </div>
            <div class="d-flex align-items-center ml-75">
              <span style="margin-top: 15px;" class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
              <span style="margin-right: 15px;margin-top: 15px;">Price</span>
            </div> -->
          </div>
        </div>

        <!-- chart -->
        <!-- <vue-apex-charts
          id="RevenueReport"
          type="area"
          :options="areaChartOptions"
          :series="this.series"
          height="280px"
        /> -->
        <chartjs-component-line-chart
        :height="280"
        :data="this.chartDataComputed"
        :options="chartjsData.lineAreaChart.options"
      />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import { methods } from 'vue-echarts'
import { areaChartOptions } from './chartOptions'
import ChartjsComponentLineChart from './charts-components/ChartjsComponentLineChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    ChartjsComponentLineChart,
  },
  directives: {
    Ripple,
  },
  computed: {
    joke() {
      return this.$store.getters.getCurrentCompdata
    },
    ticker() {
      return this.joke.Ticker
    },
    movingAverage() {
            let arr = Object.values(this.joke.Prices);
            let result = [];

            // Define the average calculation function
            const calculateAverage = (arr) => {
                return arr.reduce((acc, val) => acc + val, 0) / arr.length;
            };

            for (let i = 0; i < arr.length; i++) {
                if (i >= 4) {
                    let avg = (arr[i] + arr[i-1] + arr[i-2] + arr[i-3] + arr[i-4]) / 5;
                    result.push(avg.toFixed(2));
                } else {
                    let avg = calculateAverage(arr.slice(0, i + 1));
                    result.push(avg);
                }
            }

            return result;
    },

    series() {
      if (this.joke.MarketCap === undefined) {
        return [{ data: [] }]
      }
      return [
        { data: this.movingAverage.slice(-10).map(avg => (avg * 1.4).toFixed(2)), name: 'MA * 1.4',  fill: '-1'},
        { data: this.movingAverage.slice(-10).map(avg => (avg * 1.2).toFixed(2)), name: 'MA * 1.2',  fill: '-1' },
        { data: this.movingAverage.slice(-10).map(avg => (avg * 0.8).toFixed(2)), name: 'MA * 0.8' , fill: '-1'},
        { data: this.movingAverage.slice(-10).map(avg => (avg * 0.6).toFixed(2)), name: 'MA * 0.6', fill: false },
        { data: this.movingAverage.slice(-10), name: 'Value', fill: false },
        { data: Object.values(this.joke.Prices).slice(-10), name: 'Price', fill: false }
      ]
    },
    chartDataComputed() {
      if (this.joke.MarketCap === undefined) {
        return { labels: [],
        datasets: []}
      }
      // Define the computed property to return the chart data
      return {
        labels: [
          '7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12',
          
        ],
        datasets: [
  {
    label: 'Africa (MA * 1.4)',
    data: this.movingAverage.slice(-10).map(avg => (avg * 1.4)),
    lineTension: 0.4,
    fill: false,
    borderWidth: 0.1,
    backgroundColor: 'rgba(100, 149, 237, 0.2)',
    borderColor: 'rgba(54, 162, 235, 0.2)',
    pointStyle: 'circle',
    pointRadius: 0,
    pointBorderColor: 'transparent',

  },
  {
    label: 'Asia (MA * 1.2)',
    data: this.movingAverage.slice(-10).map(avg => (avg * 1.2)),
    lineTension: 0.4,
    fill:false,
    borderWidth: 3,
    borderDash: [5, 5],
    backgroundColor: 'rgba(0, 0, 139, 0.8)',
    borderColor: 'rgba(54, 162, 235, 0.2)',
    pointStyle: 'circle',
    pointRadius: 0,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 5,
  },
  {
    label: 'Europe (MA * 0.8)',
    data: this.movingAverage.slice(-10).map(avg => (avg * 0.8)),
    lineTension: 0.4,
    borderWidth: 0.1,
    fill: '-2',
    backgroundColor: 'rgba(153, 102, 255, 0.2)',
    borderColor: 'rgba(54, 162, 235, 0.2)',
    pointStyle: 'circle',
    pointRadius: 0,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 5,
  },
  {
    label: 'Africa (MA * 0.6)',
    data: this.movingAverage.slice(-10).map(avg => (avg * 0.6)),
    lineTension: 0.4,
    fill: '4',
    borderWidth: 0.1,
    backgroundColor: 'rgba(54, 162, 235, 0.2)',
    borderColor: 'rgba(54, 162, 235, 0.2)',
    pointStyle: 'circle',
    pointRadius: 0,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 5,
  },
  {
    label: 'Asia (MA * 1.6)',
    data: this.movingAverage.slice(-10).map(avg => (avg * 1.6)),
    lineTension: 0.4,
    borderWidth: 0.1,
    fill: false,
    backgroundColor: 'rgba(255, 99, 132, 0.2)',
    borderColor: 'rgba(54, 162, 235, 0.2)',
    pointStyle: 'circle',
    pointRadius: 0,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 5,
  },
  {
    label: 'Europe (Prices)',
    data: Object.values(this.joke.Prices).slice(-10),
    lineTension: 0.4,
    fill: false,
    backgroundColor: 'rgba(153, 102, 255, 0.2)',
    borderColor: 'rgba(153, 102, 255, 1)',
    pointStyle: 'circle',
    pointRadius: 0.5,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 5,
  }
]


      };
    }
  },
  data() {
    return {
      chartjsData,
      areaChartOptions : {
  grid: {
    show: false,
    padding: {
      left: 0,
      right: 0,
    },
  },
  chart: {
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
      x: { show: false },
  },
  stroke: {
    curve: 'smooth',
    width: 2.5,
  },
  colors: [$themeColors.primary, $themeColors.info, $themeColors.info,$themeColors.info,$themeColors.info,$themeColors.info],
  fill: {
    type: 'solid', // Typ auf 'solid' für Bereichsdiagramme setzen
    opacity: [0.2, 0.1, 0.1,0.2, 0.1, 0.1,]
  },
  xaxis: {
    type: 'numeric',
    lines: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    labels: { show: false },
  },
  yaxis: [
    {
      y: 0,
      offsetX: 0,
      offsetY: 0,
      padding: {
        left: 0,
        right: 0,
      },
    },
  ],
  fill: {
    type: 'solid', // Typ auf 'solid' für Bereichsdiagramme setzen
    opacity: [0.1, 0.1, 0.1]
  },
},
      revenue_report: {},
      revenueReport: {
        id:"revenue-report-chart",
        chartOptions: {
            chart: {
              type: 'pie',
              toolbar: { show: false },
              redrawOnParentResize: true
            },
            grid: {
              // padding: {
              //   top: -20,
              //   bottom: -10,
              // },
              yaxis: {
                lines: { show: false },
              },
            },
            
            xaxis: {
              show: false,
              categories: ['2012','2013','2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', 'Current'],
              labels: {
                show:false,
                style: {
                  colors: '#6E6B7B',
                  fontSize: '0.86rem',
                  fontFamily: 'Montserrat',
                },
              },
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
            },
            legend: {
              show: false,
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth',
            },
            colors: [$themeColors.primary, $themeColors.info],
            plotOptions: {
              bar: {
                columnWidth: '17%',
                endingShape: 'rounded',
              },
              distributed: true,
            },
            yaxis: {
              showForNullSeries: false,
              show: false,
              labels: {
                style: {
                  colors: '#6E6B7B',
                  fontSize: '0.86rem',
                  fontFamily: 'Montserrat',
                },
                formatter: function(num) {
                      if (num >= 1000000000000) {
                              return (num / 1000000000000).toFixed(1).replace(/\.0$/, '') + 'T';
                          }
                      if (num >= 1000000000) {
                          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
                      }
                      if (num >= 1000000) {
                          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                      }
                      if (num >= 1000) {
                          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                      }
                      return num;
                  }
              },
            },
          },
      },
      // budget chart
      budgetChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            sparkline: { enabled: true },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 10],
            width: [2],
          },
          colors: [$themeColors.primary, '#dcdae3'],
          tooltip: {
            show: true,
          },
        },
        methods:{
          fillGraph: function() {
            this.revenueReport.revenueReport.series= 
            [
              {
                data: Object.values(joke.MarketCap)
              },
              {
                data: Object.values(joke.simpleValue)
              },
            ]
          }
        }
      },
    }
  }
}
</script>
