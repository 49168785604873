<template>
  <b-card no-body :class="borderVariant(Math.round(((this.Value/this.Price)-1)*100))"
  
  id="DCF">
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Valuation
        </b-card-title>
        <b-card-sub-title>Free Cashflow Analysis
         </b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- badge -->
      <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
        <h5 class="font-weight-bolder mb-0 mr-1">
          {{'            '}}
          {{'            '}}
        </h5>
        <b-alert class="mt-1 mr-3"
        id="Alert"
                  :variant="alertColor(Math.round(((this.Value/this.Price)-1)*100))"
                  show
                >
                  <div class="alert-body">
                   <span>{{alertText(Math.round(((this.Value/this.Price)-1)*100))}}</span>
                  </div>
                </b-alert>
      </div>
      <!--/ badge -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        id="realtimeChart"
        type="area"
        height="400"
        sm:height="200"
        :options="chartOptions" 
        :series="this.Series"
      />
      <div class="d-md-none">
  <!-- Show badges vertically stacked on mobile -->
  <div class="text-center mt-1">
    <div>
      <b-badge class="mb-1" variant="light-success">{{ optimisticValueText() }}</b-badge>
    </div>
    <div>
      <b-badge class="mb-1" variant="light-info">{{ realisticValueText() }}</b-badge>
    </div>
    <div>
      <b-badge variant="light-primary">{{ pessimisticValueText() }}</b-badge>
    </div>
  </div>
</div>

<div class="d-none d-md-flex justify-content-around mt-3">
  <!-- Show badges horizontally next to each other on non-mobile devices -->
  <div class="text-center">
    <b-badge variant="light-success">{{ optimisticValueText() }}</b-badge>
  </div>
  <div class="text-center">
    <b-badge variant="light-info">{{ realisticValueText() }}</b-badge>
  </div>
  <div class="text-center">
    <b-badge variant="light-primary">{{ pessimisticValueText() }}</b-badge>
  </div>
</div>
            
    </b-card-body>
  </b-card>
  
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge, BAlert
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { EventBus } from '@/main'
import { $themeColors } from '@themeConfig'







export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BAlert



  },
  computed: {
    Price() {
      return this.$store.getters.getCurrentCompdata.LastPrice
    },
    discountRate () {
      return (this.$store.getters.getDiscountRate)*(-1)
    },
    discountRateRealistic () {
      return (this.$store.getters.getDiscountRateRealistic)*(-1)
    },
    discountRatePessimistic () {
      return (this.$store.getters.getDiscountRatePessimistic)*(-1)
    },
    YearsOptimistic () {
      return this.$store.getters.getYearsOptimistic
    },
    YearsRealistic () {
      return this.$store.getters.getYearsRealistic
    },
    YearsPessimistic () {
      return this.$store.getters.getYearsPessimistic
    },



    Series() {
      const Fcf = this.$store.getters.getCurrentCompdata.FcfPerShare;
      console.log("Fcf:", Fcf);

      const optimisticData = this.generateData(Fcf, this.discountRate, this.YearsOptimistic);
      const realisticData = this.generateData(Fcf, this.discountRateRealistic, this.YearsRealistic);
      const pessimisticData = this.generateData(Fcf, this.discountRatePessimistic, this.YearsPessimistic);

      console.log("Optimistic Data:", optimisticData);
      console.log("Realistic Data:", realisticData);
      console.log("Pessimistic Data:", pessimisticData);

      return [
        { name: 'Optimistic', data: optimisticData },
        { name: 'Realistic', data: realisticData },
        { name: 'Pessimistic', data: pessimisticData },
      ];
    },

    Value() {
      const Fcf = this.$store.getters.getCurrentCompdata.FcfPerShare;
      console.log("Fcf:", Fcf);

      const optimisticValue = this.calculateValue(Fcf, this.discountRate, this.YearsOptimistic);
      const realisticValue = this.calculateValue(Fcf, this.discountRateRealistic, this.YearsRealistic);
      const pessimisticValue = this.calculateValue(Fcf, this.discountRatePessimistic, this.YearsPessimistic);

      console.log("Optimistic Value:", optimisticValue);
      console.log("Realistic Value:", realisticValue);
      console.log("Pessimistic Value:", pessimisticValue);

      return Math.round(optimisticValue + realisticValue + pessimisticValue);
    },

  },
  data: function() {
      return {
        Rate:'',
        chartOptions: {
          chart: {
            id: 'vuechart-example',
            toolbar: {
              show: false
            },
          },
           legend: {
        show: true,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Montserrat',
        horizontalAlign: 'left',
      },
            toolbar: {
              show: false
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth',
            },
            colors: [$themeColors.success, $themeColors.info, $themeColors.primary],
            marker: {
              size: 0,
            },
            xaxis: {
              categories: [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053, 2054, 2055, 2056, 2057, 2058, 2059, 2060, 2061, 2062, 2063, 2064, 2065, 2066, 2067, 2068, 2069, 2070, 2071]

            },
            yaxis: {
              min:0,
              labels: {
                formatter: function (val) {
                    return val.toFixed(2)
                }
              }
            }
        },
        series: [{
          data: [30, 40, 35, 50, 49, 60, 70, 91]
        }]
      }
  },
  watch: {
    Rate(newVal, oldVal) {
      console.log("Rate changed:", newVal, oldVal);
      this.updateChart(newVal);
    },

    YearsOptimistic(newVal, oldVal) {
      console.log("YearsOptimistic changed:", newVal, oldVal);
      this.updateChart();
    },

    YearsRealistic(newVal, oldVal) {
      console.log("YearsRealistic changed:", newVal, oldVal);
      this.updateChart();
    },

    YearsPessimistic(newVal, oldVal) {
      console.log("YearsPessimistic changed:", newVal, oldVal);
      this.updateChart();
    },
  },
  methods: {
    optimisticValueText() {
      return `Optimistic Value: ${this.formatValue(this.calculateValue(this.$store.getters.getCurrentCompdata.FcfPerShare, this.discountRate, this.YearsOptimistic))}`;
    },
    realisticValueText() {
      return `Realistic Value: ${this.formatValue(this.calculateValue(this.$store.getters.getCurrentCompdata.FcfPerShare, this.discountRateRealistic, this.YearsRealistic))}`;
    },
    pessimisticValueText() {
      return `Pessimistic Value: ${this.formatValue(this.calculateValue(this.$store.getters.getCurrentCompdata.FcfPerShare, this.discountRatePessimistic, this.YearsPessimistic))}`;
    },
    formatValue(value) {
      return `$${value.toFixed(2)}`;
    },
    generateData(Fcf, discountRate, years) {
      console.log("Generating Data for", years, "years");
      return Array.from({ length: years }, (_, i) => Fcf / Math.pow((1 + discountRate), i + 1));
    },

    calculateValue(Fcf, discountRate, years) {
      console.log("Calculating Value for", years, "years");
      return Array.from({ length: years }, (_, i) => Fcf / Math.pow((1 + discountRate), i + 1))
        .reduce((acc, val) => acc + val, 0);
    },

    updateChart() {
      const optimisticData = this.generateData(
        this.$store.getters.getCurrentCompdata.FcfPerShare,
        this.discountRate,
        this.YearsOptimistic
      );
      const realisticData = this.generateData(
        this.$store.getters.getCurrentCompdata.FcfPerShare,
        this.discountRateRealistic,
        this.YearsRealistic
      );
      const pessimisticData = this.generateData(
        this.$store.getters.getCurrentCompdata.FcfPerShare,
        this.discountRatePessimistic,
        this.YearsPessimistic
      );

      // Update the series option
      this.series = [
        { name: 'Optimistic', data: optimisticData },
        { name: 'Realistic', data: realisticData },
        { name: 'Pessimistic', data: pessimisticData },
      ];
      },alertColor(value) {
            if(value < -10) {
                return 'danger'
            } else if (value >= -10 && value < 10) {
                return 'warning'
            } else {
                return 'success'
            }
          },
      alertText(value) {
            if(value < -10) {
                return `Overvalued ! The current market price is ${Math.abs(value)} % higher than your calculated value`
            } else if (value >= -10 && value < 10) {
                return "Fairly valued ! The current market price and your calculated value are fairly similar"
            } else {
                return `Undervalued ! Your calculated value is ${value}% higher than the current market price`
            }
          },
          borderVariant(value) {
            if(value < -10) {
                return 'border-danger'
            } else if (value >= -10 && value < 10) {
                return 'border-warning'
            } else {
                return 'border-success'
            }
    }


    },
 created() {
  // Using the server bus
  // EventBus.$on('fun', (Rate) => {
  //   this.Rate = Rate;
  // });
  
 }
}
</script>
