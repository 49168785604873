<template>
  <b-card>
    <b-tabs
    v-model="tabIndex">
      
      <b-tab lazy
      :disabled="isTabsDisabled">
        <template #title>
          
          <span>Balance Sheet</span>
        </template>

        <balance-sheet-vue/>

      </b-tab>
      <b-tab lazy
      :disabled="isTabsDisabled">
        <template #title>
          
          <span>Income Statement</span>
        </template>

        <income-statement/>

      </b-tab>
      <b-tab lazy
      :disabled="isTabsDisabled">
        <template #title>
          <span>Cashflow Statement</span>
        </template>

        <cashflow-statement/>
        
      </b-tab>

  
    </b-tabs>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BTabs, BTab, BCardText,BModal  } from 'bootstrap-vue'

import { doc, getDoc, docRef,docSnap } from "firebase/firestore";
import { getAuth  } from "firebase/auth";
import { db } from '@/firebase/index'
import SearchVue from '../Search/Search.vue'
import Ripple from 'vue-ripple-directive'
import SearchBarVue from '../Search/SearchBar.vue'
import { EventBus } from '@/main'
import _ from 'lodash';
import CashflowStatement from './CashflowStatement.vue';
import IncomeStatement from './IncomeStatement.vue';
import BalanceSheetVue from './BalanceSheet.vue';


export default {
  components: {
    BCardCode,
    BCardText,
    BTabs,
    BTab,
    CashflowStatement,
    IncomeStatement,
    BalanceSheetVue
    
  },
  directives: {
    Ripple,
  }, 
  data() {
      return {
        tabIndex: 1
      }
  },
  async created() {
    EventBus.$on('tabindexx',(data) => {
        this.tabIndex= data
        this.$refs['my-modal'].hide()
        console.log(tabIndex)
        });
    const auth = getAuth();
    const user =  await auth.currentUser.uid;
    const docRef = doc(db, "users", user);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      this.$store.dispatch('setUser',docSnap.data())
      console.log(docSnap.data())
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    this.checkTutorial()
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    checkTutorial() {
      if(this.$store.getters.getUser.tutorial === true) {
        this.$tours.vuexyTour.start()
      }
    },
  },
  computed: {
    isTabsDisabled() {
      // return this.$store.CurrentCompdata.length === 0;
    }
  },
  mounted() {
    this.checkTutorial()
    this.$emit('elementReady', 'SearchTab');
  }
}
</script>
