w<template>
    
  <statistic-card-with-area-chart
        
          color="warning"
          :statistic="`${((this.CurrentYield/this.Price)*100).toFixed(2)}%`"
          statistic-title="Dividend-Yield"
          :chart-data="this.series"
        />
</template>
<!-- :statistic="`${((this.CurrentYield/this.Price)*100).toFixed(2)}%`" -->
<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { EventBus } from '@/main'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'

const $barColor = '#f3f3f3'

export default {
  components: {
    BCard,
    VueApexCharts,
    StatisticCardWithAreaChart
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    kFormatter
  },
  computed: {
    joke() {
      return this.$store.getters.getCurrentCompdata
    },
    series() {
      if (this.joke.DividendYield === undefined) {
        return this.series = [{data: []}]
      }
      return this.series =
        [
          {data: Object.values(this.joke.DividendYield).slice(-5)},
        ]
    },
    CurrentYield() {
      if (this.joke.DividendYield === undefined) {
        return this.CurrentYield = 0 
      } 
      return this.CurrentYield = Object.values(this.joke.DividendYield).slice(-1)[0]
      
    },
    Price() {
      return this.Price = this.joke.LastPrice
    },
  },
  data() {
    return {

      statisticsOrder: {
        chartOptions: {
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
            redrawOnParentResize: true
          },
          plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
          endingShape: 'rounded',
          startingShape: "rounded",
        },},
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          // plotOptions: {
          //   bar: {
          //     horizontal: false,
          //     columnWidth: '20%',
          //     startingShape: 'rounded',
          //     colors: {
          //       backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
          //       backgroundBarRadius: 5,
          //     },
          //   },
          // },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.warning],

          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
}
</script>
