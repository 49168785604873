<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            Oversimplified Valuation
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
              <span>Value</span>
            </div>
            <div class="d-flex align-items-center ml-75">
              <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
              <span>Price</span>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          type="area"
          height="270"
          :options="revenueReport.chartOptions"
          :series="this.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import { methods } from 'vue-echarts'

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  computed: {
    joke() {
      return this.$store.getters.getCurrentCompdata
    },
    series() {
      if (this.joke.MarketCap === undefined) {
        return this.series = [{data: []}]
      }
      return this.series =
        [
        {data: Object.values(this.joke.simpleValue)},
          {data: Object.values(this.joke.Prices)}
        ]
    }
  },
  data() {
    return {
      revenue_report: {},
      revenueReport: {
        chartOptions: {
            chart: {
              type: 'pie',
              toolbar: { show: false },
            },
            grid: {
              // padding: {
              //   top: -20,
              //   bottom: -10,
              // },
              yaxis: {
                lines: { show: false },
              },
            },
            
            xaxis: {
              show: false,
              categories: ['2012','2013','2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', 'Current'],
              labels: {
                show:false,
                style: {
                  colors: '#6E6B7B',
                  fontSize: '0.86rem',
                  fontFamily: 'Montserrat',
                },
              },
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
            },
            legend: {
              show: false,
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth',
            },
            colors: [$themeColors.primary, $themeColors.warning],
            plotOptions: {
              bar: {
                columnWidth: '17%',
                endingShape: 'rounded',
              },
              distributed: true,
            },
            yaxis: {
              showForNullSeries: false,
              show: false,
              labels: {
                style: {
                  colors: '#6E6B7B',
                  fontSize: '0.86rem',
                  fontFamily: 'Montserrat',
                },
                formatter: function(num) {
                      if (num >= 1000000000000) {
                              return (num / 1000000000000).toFixed(1).replace(/\.0$/, '') + 'T';
                          }
                      if (num >= 1000000000) {
                          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
                      }
                      if (num >= 1000000) {
                          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                      }
                      if (num >= 1000) {
                          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                      }
                      return num;
                  }
              },
            },
          },
      },
      // budget chart
      budgetChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            sparkline: { enabled: true },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 10],
            width: [2],
          },
          colors: [$themeColors.primary, '#dcdae3'],
          tooltip: {
            enabled: false,
          },
        },
        methods:{
          fillGraph: function() {
            this.revenueReport.revenueReport.series= 
            [
              {
                data: Object.values(joke.MarketCap)
              },
              {
                data: Object.values(joke.simpleValue)
              },
            ]
          }
        }
      },
    }
  }
}
</script>
