<template>
  <b-row v-if="data">
    <b-col
      v-for="item in data"
      :key="item.icon"
      xl="3"
      sm="6"
      :class="item.customClass"
    >
      <b-card no-body  class="card-statistics">
        <b-card-header>
          <b-card-title>{{ item.title }}</b-card-title>
        </b-card-header>
        <b-card-body class="">
          <!-- Hier wird das Pie Chart von ApexCharts gerendert -->
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            
            <b-media-body class="my-auto">
              <h2 class="font-weight-bolder mb-0">{{ item.subtitle }}</h2>
              <h1 class="font-weight-bolder mb-0">{{ item.value }}</h1>
            </b-media-body>
          </b-media>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BMedia, BAvatar, BMediaAside, BMediaBody } from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    ApexCharts: VueApexCharts.apexcharts,
    apexchart: VueApexCharts.component,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    // Beispielserie für das Pie Chart
    this.data.forEach(item => {
      item.chartOptions = {
        labels: ['Series 1', 'Series 2', 'Series 3', 'Series 4'],
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      };
      item.chartSeries = [44, 55, 13, 43];
    });
  },
};
</script>
