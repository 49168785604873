<template>
  <div>
    <!-- Render table on larger screens -->
    <b-card id="Institution" v-if="joke" class="d-none d-md-block">
      <div class="table-responsive">
        <table class="table table-borderless">
          <!-- Table header -->
          <thead><!-- Hier die table-head-Klasse anwenden -->
            <tr class="font-weight-bolder-header">
              <th scope="col"><b-card-title >Institution</b-card-title ></th>
              <th scope="col"><b-card-title >Ownership</b-card-title ></th>
              <th scope="col"><b-card-title >Shares</b-card-title ></th>
              <th scope="col"><b-card-title >Value</b-card-title ></th>
            </tr>
          </thead>
          <!-- Table body -->
          <tbody>
            <tr v-for="(item) in companyTable" :key="item.id">
              <td class="text-muted">{{ item.Holder }}</td>
              <td>{{ (item['% Out']*100).toFixed(2)+'%' }}</td>
              <td>{{ nFormatter(item.Shares) }}</td>
              <td>{{ '$'+nFormatter(item.Value)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>

    <!-- Render cards on mobile screens -->
    <b-card id="Institution" class="d-md-none">
      <b-card-header>
        <b-card-title>Institutional Holder </b-card-title>
        <b-card-sub-title>Graph Shows Concentration of Shares</b-card-sub-title>
      </b-card-header>
      <b-card-body v-if="this.data">
        <chartjs-component-polar-area-chart
          :data="this.data"
          :options="chartjsData.polarChart.options"
        />
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BTable, BAvatar, BImg,   BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside,  BDropdown, BDropdownItem, BAlert
} from 'bootstrap-vue'
import { methods } from 'vue-echarts';
import ChartjsComponentPolarAreaChart from './ChartjsComponentPolarAreaChart.vue'
import chartjsData from './chartjsData'
import { $themeColors } from '@themeConfig'

const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}


export default {
  components: {
    BCard,
    ChartjsComponentPolarAreaChart,
    BTable,
    BAvatar,
    BImg,BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside,BDropdown, BDropdownItem, BAlert
  },
  computed: {
    joke() {
      return this.$store.getters.getCurrentCompdata
    },
    companyTable() { 
      return this.companyTable = this.joke.Instituion
    },
    marketcap() {
      return this.$store.getters.getCurrentCompdata.marketcap
    },
    data() {
  if (this.joke === undefined) {
    return {
      labels: ['a','b'],
      datasets: [
        {
          label: 'Population (millions)',
          backgroundColor: [
            chartColors.primaryColorShade,
            chartColors.warningColorShade,
            $themeColors.primary,
            chartColors.infoColorShade,
            chartColors.greyColor,
            chartColors.successColorShade,
          ],
          data: [0],
          borderWidth: 0,
        },
      ],
    };
  } else {
    return {
      labels: this.companyTable.map(item => item.Holder),
      datasets: [
        {
          label: 'Population (millions)',
          backgroundColor: [
            chartColors.primaryColorShade,
            chartColors.warningColorShade,
            $themeColors.primary,
            chartColors.infoColorShade,
            chartColors.greyColor,
            chartColors.successColorShade,
            $themeColors.primary,
            $themeColors.primary,
            $themeColors.primary,
            $themeColors.primary,
            $themeColors.info,

          ],
          data: this.companyTable.map(item => item.Value),
          borderWidth: 0,
        },
      ],
    };
  }
}
  },
  watch: {
    companyTable: {
      handler: function(newValue, oldValue) {
        // Update the data for the graph when companyTable changes
        this.updateChartData(newValue);
      },
      deep: true // This ensures that changes within the array elements are detected
    }
  },
  data() {
    return {  
      chartjsData,
    }
  },
  methods: {
    updateChartData(companyTable) {
      // Update chart data based on the new companyTable data
      this.data = {
        labels: companyTable.map(item => item.Holder),
        datasets: [{
          label: 'Population (millions)',
          backgroundColor: [
            chartColors.primaryColorShade,
            chartColors.warningColorShade,
            $themeColors.primary,
            chartColors.infoColorShade,
            chartColors.greyColor,
            chartColors.successColorShade,
            $themeColors.primary,
            $themeColors.primary,
            $themeColors.primary,
            $themeColors.primary,
            $themeColors.info,
          ],
          data: companyTable.map(item => item.Value),
          borderWidth: 0,
        }],
      };
    },
    nFormatter(num) {
     if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
     }
     if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
     }
     if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
     }
     return num;
}
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
.table-head {
    font-weight: 650 !important;
    font-size: 1.6rem !important;
    margin-bottom: 1.53rem !important;
}
.transaction-item {
    margin-bottom: 5px; /* Add margin at the bottom to separate each row */
  }
  .transaction-details {
    display: flex;
    justify-content: space-between; /* Align items on opposite ends */
  }
  .transaction-title-holder {
    margin-right: 0px; /* Add margin between Holder and % Out */
  }
</style>
