<template>
  <b-card id="ShortSummary" v-if="data" class="card-congratulation-medal">
    <!-- Header Bereich -->
    <div class="row justify-content-between mt-0">
      <div class="">
        <b-card class="rounded-card">
          <div class="d-flex align-items-center">
            <div>
              <h5 class="card-title mb-0">{{ this.ticker }}</h5>
              <p class="card-text mb-0">{{ this.name }}</p>
            </div>
          </div>
        </b-card>
      </div>
      <div class=" text-right">
        <b-card class="rounded-card">
          <div class="d-flex align-items-center">
            <div class="ml-auto">
              <h5 class="card-title mb-0">${{ this.Price }}</h5>
              <p class="card-text mb-0">{{ this.sector }}</p>
            </div>
          </div>
        </b-card>
      </div>
    </div>

    <!-- Divider -->
    <hr class="custom-divider">

    <!-- Hauptinhalt Bereich -->
    <b-row class="mt-0">
      <b-col>
        <b-button
          class="w-50"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :variant="variant(isNameInArray)"
          @click="whichmethods()"
        >
          {{ buttonText }}
        </b-button>
      </b-col>
      <b-col>
        <b-button
          class="w-50 float-right"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :variant="isNameInArray ? variant(true) : 'outline-secondary'"
          @click="Hallo = true"
        >
          Add to Portfolio
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      class="rounded-header text-title"
      id="modal-login2"
      cancel-variant="outline-danger"
      ok-variant="info"
      ok-title="Login/Register"
      cancel-title="Close"
      centered
      title="You need to Be Signed up for access"
      size="md"
      @ok="firelogin()"
      @cancel="handleCancel()"
      @hide="handleCancel()"
    >
      <b-form>
        <b-form-group class="rounded-header" >
          <b-form-input
            id="email"
            type="email"
            placeholder="Email Address"
            class="rounded-input"
            v-model="userEmail"
          />
        </b-form-group>
        <b-form-group class="rounded-header" >
          <b-form-input
            id="password"
            type="password"
            placeholder="Password"
            class="rounded-input"
            v-model="password"
          />
        </b-form-group>
        <div class="text-center">
        <hr>
        <span class="">OR</span>
        <hr >
      </div>

        <button type="button" class="google-sign-in-button btn btn-outline-secondary text-center btn-block" @click="GoogleSignIn">
              Sign In with Google
            </button>
      </b-form>
    </b-modal>
  </b-card>
  
</template>



<script>
import {
  BCard, BCardText, BLink, BButton, BImg, BModal, VBModal,BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import { getAuth,onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, updateDoc } from "firebase/firestore"; 
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { EventBus } from '@/main'
import useJwt from '@/auth/jwt/useJwt'
import { db } from '@/firebase/index'
import {  snapshot, getDoc, documentId,query,collection,where, getDocs, arrayRemove, FieldValue,deleteField } from "firebase/firestore";
import useAppConfig from '@core/app-config/useAppConfig'
import {  signInWithEmailAndPassword,GoogleAuthProvider,createUserWithEmailAndPassword , signInWithPopup  } from "firebase/auth";
import axios from 'axios';

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
    VBModal,
    BModal,
    BForm, 
    BFormInput, 
    BFormGroup,

  },
  data() {
    return {
      Price: 0,
    }
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
  computed: {
    skin() {
      return this.$store.state.appConfig.layout.skin
    },
    joke() {
      return this.$store.getters.getCurrentCompdata
    },
    user() {
      return this.user = this.$store.state.getters.getUser
    },
    Watchlist() {
      return this.$store.getters.getWatchlist
    },
    name() { 
      return this.name = this.joke.Name
    },
    ticker() { 
      return this.name = this.joke.Ticker
    },
    sector() { 
      return this.sector = this.joke.Sector
    },
    // Price() {
    //   return this.Price = this.joke.LastPrice
    // },
    isNameInArray() {
      return this.Watchlist.some(item => item.Name === this.name);
    },
    buttonText() {
      if (this.isNameInArray === false) {
        return 'Add to Watchlist';
      } else {
        return 'Remove from Watchlist';
      }
    },
    // Logo() {
    //   return this.Logo = `data:image/png;base64,${this.joke.Logo['_byteString']['binaryString']}`;
    // }
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  async created() {
    EventBus.$on('suggestionSelected',(data) => {
      this.getWatchlist()
      this.fetchSharePrices(data.ticker);
        });
  },
  methods: {
    async fetchSharePrices(ticker) {
      console.log('Fetching share prices...', ticker);
        try {
          const response = await axios.get(`https://eodhd.com/api/eod/${ticker}?filter=last_close&api_token=652527b5bf7089.92212513&fmt=json`);
          console.log(`Response for ${ticker}:`, response.data);
          this.Price = response.data; // Update sharePrice for the current company
        } catch (error) {
          console.error(`Error fetching share price for ${ticker}:`, error);
          // Handle error if needed
        }
      },
  //   async firelogin() {
  //     console.log('FIIIIIIIIIIRE')
  //     const auth = getAuth();
  //     await signInWithEmailAndPassword(auth, this.userEmail, this.password)
  //     onAuthStateChanged(auth, async (user) => {
  //       if (user) {
  //         console.log(user.uid)
  //         const uid = user.uid;
  //         const docRef = doc(db, "users", uid);
  //         const docSnap = await getDoc(docRef);
  //         const userData = docSnap.data().userData
  //         localStorage.setItem('userData', JSON.stringify(userData))
  //         this.$ability.update(userData.ability)
  //         this.$bvModal.hide('modal-login2')
  //       } else {
          
  //       }
      
  //       }) .catch((error) => {
  //         console.log('USer Nicht Vorhanden und wird jetzt registriert')
  //         createUserWithEmailAndPassword(auth, this.email, this.password).then(credential => {
  //           if (credential && credential.user) {
  //             console.log(credential.user.uid)
  //             setDoc(doc(db, "users", credential.user.uid), {

  //               username: this.username,
  //               email: this.email,
  //               tutorial: false,
  //               role: "user",
  //               userData: {
  //                 id: 2,
  //                 fullName: this.username,
  //                 username: this.username,
  //                 password: 'client',
  //                 // eslint-disable-next-line global-require
  //                 avatar: '',
  //                 email: this.email,
  //                 role: 'client',
  //                 ability: [
  //                   {
  //                     action: 'read',
  //                     subject: 'ACL',
  //                   },
  //                   {
  //                     action: 'read',
  //                     subject: 'Auth',
  //                   },
  //                 ],
  //               },
              
  //             });
  //             ;}})

  //             this.$refs.registerForm.validate().then(success => {
  //             if (success) {
  //               useJwt.register({
  //                 username: this.username,
  //                 email: this.email,
  //                 password: this.password,
  //               })
  //                 .then(response => {
  //                   useJwt.setToken(response.data.accessToken)
  //                   useJwt.setRefreshToken(response.data.refreshToken)
  //                   localStorage.setItem('userData', JSON.stringify(response.data.userData))
  //                   this.$ability.update(response.data.userData.ability)
  //                   this.$router.push('/companyanalysis')
  //                 })
  //                 .catch(error => {
  //                   this.$refs.registerForm.setErrors(error.response.data.error)
  //                 })
  //             }
  //           }) 
  //       .catch((error) => {

  //         const errorCode = error.code;
  //         const errorMessage = error.message;
  //         alert(errorMessage)
  //         console.log(errorCode)
  //         // ..
  // });
  // });
  //   },
  async firelogin() {
  console.log('FIIIIIIIIIIRE');
  const auth = getAuth();

  // Lokale Kopien der Anmeldedaten speichern
  const email = this.userEmail;
  const password = this.password;
  // Generiere den Benutzernamen aus der E-Mail-Adresse
  const username = email.split('@')[0];

  try {
    // Versuche den Benutzer anzumelden
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Überprüfe den Authentifizierungszustand
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log(user.uid);
        const uid = user.uid;
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data().userData;
          localStorage.setItem('userData', JSON.stringify(userData));
          this.$ability.update(userData.ability);
          this.$bvModal.hide('modal-login2');
          window.location.reload(); // Seite neu laden
        } else {
          console.log("No such document!");
        }
      }
    });
  } catch (error) {
    // Wenn Benutzer nicht existiert, erstelle einen neuen Benutzer
    console.log('User nicht vorhanden, wird jetzt registriert');

    try {
      // Überprüfe, ob `email` und `password` definiert sind
      if (!email || !password) {
        console.error('Fehlende Eingabedaten:', {
          username: username,
          email: email,
          password: password
        });
        throw new Error('Username, Email oder Passwort ist nicht definiert');
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Speichere die Benutzerdaten in der Firestore-Datenbank
      await setDoc(doc(db, "users", user.uid), {
        username: username,
        email: email,
        tutorial: false,
        role: "user",
        userData: {
          id: 2,
          fullName: username,
          username: username,
          password: 'client',
          avatar: '',
          email: email,
          role: 'client',
          ability: [
            { action: 'read', subject: 'ACL' },
            { action: 'read', subject: 'Auth' },
          ],
        },
      });

      // Registrierung erfolgreich, führe den Rest aus
      const response = await useJwt.register({
        username: username,
        email: email,
        password: password,
      });

      useJwt.setToken(response.data.accessToken);
      useJwt.setRefreshToken(response.data.refreshToken);
      localStorage.setItem('userData', JSON.stringify(response.data.userData));
      this.$ability.update(response.data.userData.ability);
      this.$router.push('/companyanalysis');
      window.location.reload(); // Seite neu laden

    } catch (registrationError) {
      console.error("Fehler bei der Registrierung: ", registrationError);
    }
  }
},
  // .catch (registerError) {
  //     this.$refs.registerForm.setErrors(registerError.response.data.error);
  //     console.error(registerError);
  //   }
    // async firelogin() {
    //   const auth = getAuth();
    //   try {
    //     // Versuche, den Benutzer einzuloggen
    //     await signInWithEmailAndPassword(auth, this.userEmail, this.password);
    //     this.handleAuthStateChanged(auth);
    //   } catch (error) {
    //     if (error.code === 'auth/user-not-found') {
    //       // Wenn der Benutzer nicht gefunden wurde, registriere ihn
    //       try {
    //         await createUserWithEmailAndPassword(auth, this.userEmail, this.password);
    //         this.handleAuthStateChanged(auth);
    //       } catch (regError) {
    //         console.error('Fehler bei der Registrierung:', regError);
    //       }
    //     } else {
    //       console.error('Fehler beim Einloggen:', error);
    //     }
    //   }
    // },
    // handleAuthStateChanged(auth) {
    //   onAuthStateChanged(auth, async (user) => {
    //     if (user) {
    //       console.log(user.uid);
    //       const uid = user.uid;
    //       const docRef = doc(db, "users", uid);
    //       const docSnap = await getDoc(docRef);

    //       if (docSnap.exists()) {
    //         // Dokument existiert, Benutzer einloggen
    //         const userData = docSnap.data().userData
    //       localStorage.setItem('userData', JSON.stringify(userData))
    //       this.$ability.update(userData.ability)
    //         this.$bvModal.hide('modal-login2');
    //       } else {
    //         // Dokument existiert nicht, erstelle neues Benutzer-Dokument
    //         const newUser = {
    //           userData: {
    //             email: this.userEmail,
    //             ability: 'defaultAbility' // Setze hier die Standardwerte für den neuen Benutzer
    //           }
    //         };
    //         await setDoc(docRef, newUser);
    //         localStorage.setItem('userData', JSON.stringify(newUser.userData));
    //         this.$ability.update(newUser.userData.ability);
    //         this.$bvModal.hide('modal-login2');
    //       }
    //     } else {
    //       console.log('Kein Benutzer eingeloggt');
    //     }
    //   });
    // },
    async GoogleSignIn() {
      const auth = getAuth();
  const provider = new GoogleAuthProvider();

  // Prompt user to select Google account for sign-in
  signInWithPopup(auth, provider)
    .then((result) => {
      // The signed-in user info
      const user = result.user;

      // Check if user document already exists
      const userDocRef = doc(db, "users", user.uid);
      getDoc(userDocRef)
        .then((docSnapshot) => {
          if (!docSnapshot.exists()) {
            // Store user details in Firestore
            setDoc(userDocRef, {
              username: user.displayName,
              email: user.email,
              tutorial: false,
              FreeTrial: 0,
              role: "user",
              userData: {
                id: 2,
                fullName: user.displayName,
                username: user.displayName,
                password: 'client',
                avatar: '', // You can update this with user's profile picture if available
                email: user.email,
                role: 'client',
                ability: [
                  {
                    action: 'read',
                    subject: 'ACL',
                  },
                  {
                    action: 'read',
                    subject: 'Auth',
                  },
                ],
              },
            });
          }

          // Proceed with registration
          useJwt.register({
            username: user.displayName,
            email: user.email,
            password: 'dummyPassword', // Dummy password?
          })
          .then(response => {
            // Handle successful registration
            useJwt.setToken(response.data.accessToken)
            useJwt.setRefreshToken(response.data.refreshToken)
            localStorage.setItem('userData', JSON.stringify(response.data.userData))
            this.$ability.update(response.data.userData.ability)
            this.$bvModal.hide('modal-login3');
            window.location.reload();
            // Move navigation here
          })
          .catch(error => {
            // Handle registration errors
            console.error("Error during registration:", error);
          });
        })
        .catch((error) => {
          // Handle Firestore query error
          console.error("Error querying Firestore:", error);
        });
    })
    .catch((error) => {
      // Handle errors here
      console.error("Error during Google registration:", error);
    });
  },
    kFormatter,
    async addToWatch()  {
      const auth = getAuth();
      const user = auth.currentUser.uid;
      await setDoc(doc(db,"users",user),({ Watchlist: {[this.name]: this.Price}}),{ merge: true })
    },
    showToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Watchlist',
          icon:"HeartIcon",
          text: `${this.name} was added`,
          variant:"primary",
        },
      })
    },
    async getWatchlist() {
      const table = []
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          const bata = docSnap.data().Watchlist
          if (docSnap.data().Watchlist !== undefined) {
            const array = Object.keys(bata)
            const ocRef= collection(db, "companies")
            const q = query(ocRef, where(documentId(), 'in', array));
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              const data = {"Name":doc.id}
              table.push(data)
            });
            this.$store.dispatch('setWatchlist',table)
            console.log(table)
          }  else {
            this.EmptyWatchlist('TEST123')
          }
        }else {
         
        }
      });
    },confirmText(Name) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.RemoveCompany(Name)
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: `${Name} successfully deleted`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    async RemoveCompany(Name) {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;
          // await updateDoc(doc(db, "users", uid), {Watchlist: FieldValue.delete(Name)} );
          await updateDoc(doc(db, "users", uid), {[`Watchlist.${Name}`]: deleteField()});
          this.getWatchlist()
        }})
    },
    variant: function (value) {
      if (value === true) {
        return "danger"
      }
      if (value === false && this.skin === 'light') {
        return "dark"
      }if (value === false && this.skin === 'dark') {
        return "light"
      }
    },
    confirmText(Name) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        customClass: {
          confirmButton: 'btn btn-dark',
          cancelButton: 'btn btn-outline-danger ml-1',
          title: 'text-black'
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.RemoveCompany(Name)
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: `${Name} successfully deleted`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    async whichmethods() {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      // If user is not authenticated, show the login modal
      this.$bvModal.show('modal-login2')
      console.log('Now User')
      return; // Exit the method to prevent further execution
    }

    // If user is authenticated, proceed with other logic
    if (this.isNameInArray) {
      this.confirmText(this.name);
    } else {
      this.addToWatch();
      this.showToast();
      this.getWatchlist();
    }
  },
  },
}
</script>
<style scoped>
/* .custom-dark-button {
  background-color: #000000;
  border-color: #343a40;
  color: #fff;
}

.custom-dark-button:hover {
  background-color: #23272b;
  border-color: #1d2124;
}

.custom-outline-secondary {
  background-color: transparent;
  border-color: #6c757d;
  color: #6c757d;
}

.custom-outline-secondary:hover {
  background-color: #6c757d;
  border-color: #545b62;
  color: #fff;
} */
.custom-divider {
  margin-top: 1px; /* Adjust this value to reduce or increase the top margin */
  border-top: 1px solid #dee2e6;
}

.reduced-height {
  height: 150px; /* Adjust this value to the desired height */
}

.w-50 {
  width: 48% !important; /* Adjust the width as necessary */
}

.w-50 + .w-50 {
  margin-left: 4%; /* Adjust the margin between buttons if needed */
}

.b-button.btn-dark {
  background-color: #000000 !important;
  border-color: #000000 !important;
}
</style>