<template>
  <b-card
    id="Insider"
    v-if="transactionData"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Insider Transactions</b-card-title>

      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item @click="updateTransactionRange(7)">
      Last 7 Transactions
    </b-dropdown-item>
    <b-dropdown-item @click="updateTransactionRange(14)">
      Last 14 Transactions
    </b-dropdown-item>
    <b-dropdown-item @click="updateTransactionRange(21)">
      Last 21 Transactions
    </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>
      <div v-if="this.transactionData.length ===0">
        <b-alert
          variant="warning"
          show
        >
          <div class="alert-body centered-div">
            <span>It seems like your currently selected company does not disclose insider transactions.</span>
          </div>
        </b-alert>
      </div>
      <div v-else>
        <div
        v-for="transaction in this.transactionData"

        :key="transaction"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="getAvatarVariant(transactionData.indexOf(transaction))"
            >
              <feather-icon
                size="18"
                :icon="transaction.avatar"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ transaction["Insider Name"] }}
            </h6>
            <small>{{ transaction.Title }}</small>
          </b-media-body>
        </b-media>
        <div
          :class="transaction.Value > 0 ? 'text-success':'text-danger'"
          class="font-weight-bolder"

        >
          {{ transaction.Value }} 
        </div>
      </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, BAlert
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BAlert,
    index: 7,
  },
  computed: {
    joke() {
     return this.$store.getters.getCurrentCompdata;
    },
    transactionData() {
      if (this.joke.Insider === undefined) {
        return this.transactionData = {}
      }
      return this.transactionData = this.joke.Insider.slice(0,this.index)
    },
    transactionData() {
      if (this.joke.Insider === undefined) {
        return [];
      }
      const endIndex = this.currentIndex + 7;
      return this.joke.Insider.slice(this.currentIndex, endIndex);
    },
  },
  methods: {
    getAvatarVariant(index) {
      const colors = ['light-primary', 'light-success', 'light-warning']; // Add more colors if needed
      return colors[index % colors.length];
    },
    updateTransactionRange(range) {
      this.currentIndex = this.joke.Insider.length - range;
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  }
}
</script>
