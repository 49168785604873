<template>
  <section id="card-text-alignment">
    <b-row>
      <!-- left align -->
      <b-col

      >
        <b-card
          title="How we estimate Value of Companiest"
          class="mb-3"
        >
          <b-card-text>
            You might be familiar with the procedure of the Discounted-Cashflow-Method for Estimating the intrinsic Value of a Company. If you do not know the specifics of the procedure or want to get familiar with the method itself click below for a structured documentation.
          </b-card-text>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            v-b-modal.modal-center
          >
            How the Method works
          </b-button>
          <b-modal
      id="modal-center"
      centered
      title="Financial Ratios Analysis"
      ok-only
      ok-title="Accept"
    >
      <b-card-text>
        <h1>Growth Rate and Discount Rate</h1>

<h2>Growth Rate:</h2>
<p>
    <strong>Main Idea:</strong> Growth rate is how fast something is increasing or decreasing over time. In finance, it often refers to how quickly a company's profits or revenue are expected to grow.
</p>
<p>
    <strong>Example:</strong> If a company's profits are expected to increase by 5% per year, the growth rate is 5%.
</p>

<h2>Discount Rate:</h2>
<p>
    <strong>Main Idea:</strong> Discount rate is a way to measure the value of money over time. It reflects the idea that getting money in the future is not as valuable as getting it today. In investing, it helps us figure out the present value of future cash flows.
</p>
<p>
    <strong>Example:</strong> If you had a choice between receiving $100 today or $100 a year from now, you might choose $100 today because of the time value of money. The discount rate helps calculate how much less the future $100 is worth in today's terms.
</p>

      </b-card-text>
    </b-modal>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow, BCol, BCard, BCardText, BButton,
  },
  directives: {
    Ripple,
  },
}
</script>
