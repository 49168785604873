<template>
  <section id="dashboard-ecommerce">
    <app-tour :steps="steps"/>
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :data="data.congratulations" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <ecommerce-statistics :data="this.Rank" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          <!--/ Bar Chart - Orders -->
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <share-buy-back :data="data.earningsChart" />
          </b-col>
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <b-col lg="8" class="match-height"> <!-- Add match-height class here -->
        
          <ecommerce-revenue-report  />
         
        
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="8">
        <ecommerce-company-table-copy-vue />
      </b-col>
      <!--/ Company Table Card -->

      <!-- Transaction Card -->
      <b-col
        lg="4"
      >
        <ecommerce-transactions :data="data.transactionData" />
      </b-col>
      <!--/ Transaction Card -->
    </b-row>
    <b-modal
      class="rounded-header text-title"
      id="modal-login"
      cancel-variant="outline-danger"
      ok-variant="info"
      v-model="showLoginModal"
      ok-title="Login"
      cancel-title="Close"
      centered
      title="You need to Be Signed up for access"
      size="md"
      @ok="firelogin()"
      @cancel="handleCancel()"
      @hide="handleCancel()"
    >
      <b-form>
        <b-form-group class="rounded-header" >
          <b-form-input
            id="email"
            type="email"
            placeholder="Email Address"
            class="rounded-input"
            v-model="userEmail"
          />
        </b-form-group>
        <b-form-group class="rounded-header" >
          <b-form-input
            id="password"
            type="password"
            placeholder="Password"
            class="rounded-input"
            v-model="password"
          />
        </b-form-group>
        <div class="text-center">
        <hr>
        <span class="">OR</span>
        <hr >
      </div>

        <button type="button" class="google-sign-in-button btn btn-outline-secondary text-center btn-block" @click="GoogleSignIn">
              Sign In with Google
            </button>
      </b-form>
    </b-modal>
  </section>
  
  
</template>

<script>
import { BRow, BCol, BOverlay,BButton,VBModal, BModal,BForm, BFormInput, BFormGroup, } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import ShareBuyBack from './ShareBuyBack.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceCompanyTableCopyVue from './EcommerceCompanyTable copy.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'
import { db } from '@/firebase/index'
import { EventBus } from '@/main'
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import AppTour from '@core/components/app-tour/AppTour.vue'
import Ripple from 'vue-ripple-directive'
import {  signInWithEmailAndPassword,GoogleAuthProvider, signInWithPopup  } from "firebase/auth";
import { getAuth,onAuthStateChanged  } from "firebase/auth";


export default {
  components: {
    VBModal,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BModal,
    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceCompanyTable,
    EcommerceTransactions,
    EcommerceCompanyTableCopyVue,
    ShareBuyBack,
    AppTour,
    BForm, 
    BFormInput, 
    BFormGroup,

  },
  data() {
    return {
      // joke:[],
      showLoginModal: false,
      compdata:{},
      data:{},
      steps: [
            {
              target: this.$refs.SearchTab,
              header: {
                title: 'Hey !',
              },
              content: 'Welcome to Ophelio this Tour Shall guide your throughout our App so that you can use this app as it is intended.',
            },
            {
              target: '#Rankings',
              header: {
                title: 'First',
              },
              content: 'We start here with the Rankings. If you Analyse any Company, rankings is intended to give you an quick overview how good your companie is.',
            },
            {
              target: '#ShortSummary',
              header: {
                title: 'Summary',
              },
              content: 'Here you can find a Short Summary of your currently analyzed company',
            },
            {
              target: '#Payout',
              header: {
                title: 'Your Return',
              },
              content: 'These 3 fields provide you with the information of the Payout-Ratio, Dividend-Yield and Stock-Buyback if they are existing',
            },
            {
              target: '#Institution',
              header: {
                title: 'Who owns the Company ?',
              },
              content: 'In this table you can see who of the big Institutions already invested in the company',
            },
            {
              target: '#Insider',
              header: {
                title: 'What do insiders do ?',
              },
              content: 'This card informs you about the recent activites of the companies insiders, their position in the company and their trading volume.',
            },
            {
              target: '#SimpleValue',
              header: {
                title: 'Is the Company expensive ?',
              },
              content: 'This graph indicates whether the company is currently over or undervalued and how it was valued in the past.',
            },
            {
              target: '#Financial_Strength',
              header: {
                title: 'Great!',
              },
              content: 'Lets Look at the companies financials on the next side. Click  Fincanical Strength in the Sidebar to continue with this Tutorial',
            }
            
          ],
      bata: {
  statisticsOrder: {
    series: [
      {
        name: '2020',
        data: [],
      },
    ],
  },
  statisticsProfit: {
    series: [
      {
        data: [],
      },
    ],
  },
    },
      
    }
  },
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  computed: {
    joke() {
      return this.$store.getters.getCurrentCompdata;
    },
    Rank() {
      if(this.joke.Rankings === undefined) {
        return (
          [
            {
              icon: 'FileIcon',
              color: 'light-primary',
              title: 'Finances',
              subtitle: 0,
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'PieChartIcon',
              color: '#338EF7',
              title: 'Profitability',
              subtitle: 0,
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'TrendingUpIcon',
              color: 'light-warning',
              title: 'Growth',
              subtitle: 0,
              customClass: 'mb-2 mb-sm-0',
            },
            {
              icon: 'DollarSignIcon',
              color: 'light-success',
              title: 'Valuation',
              subtitle: 0,
              customClass: '',
            },
          ])
      }
      return (
      [
        {
          icon: 'FileIcon',
          color: 'light-primary',
          title: 'Finances',
          subtitle: this.joke.Rankings.Financial + '/10'
          ,
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'PieChartIcon',
          color: 'light-info',
          title: 'Profitability',
          subtitle: this.joke.Rankings.Profitability + '/10',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-warning',
          title: 'Growth',
          subtitle: this.joke.Rankings.Growth + '/10',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: 'Valuation',
          subtitle: this.joke.Rankings.Valuation + '/10',
          customClass: '',
        },
      ])
    }
  },
  mounted() {
    this.checkTutorial()

  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      }),
    EventBus.$on('suggestionSelected',(data) => {
        this.compName= data.name
        this.getCompany()
        });
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
        if (user) {
          this.show = false 
          console.log(' User Found')
        } else {
          this.show = true 
          console.log('no User Found')
        }
      
        })

  },
  methods: {
    firelogin() {
      console.log('FIIIIIIIIIIRE')
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.userEmail, this.password)
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          console.log(user.uid)
          const uid = user.uid;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          const userData = docSnap.data().userData
          localStorage.setItem('userData', JSON.stringify(userData))
          this.$ability.update(userData.ability)
          this.$router.push('/companyanalysis')
        } else {
          console.log('Error')
        }
      
        })
    },
    async GoogleSignIn() {
      try {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        
        // Sign in with Google using a popup
        const result = await signInWithPopup(auth, provider);
        
        // The signed-in user info
        const user = result.user;
        
        // Now you can do whatever you want with the user object, such as storing it in Vuex or Firebase Firestore
        console.log('User signed in with Google:', user);
        if (user) {
          const uid = user.uid;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          const userData = docSnap.data().userData
          localStorage.setItem('userData', JSON.stringify(userData))
          
          console.log(userData)
          this.$ability.update(userData.ability)
        }
        
        
        // Optionally, you can redirect the user to another page or perform other actions here
      } catch (error) {
        // Handle errors here
        console.error('Error signing in with Google:', error);
        // Optionally, you can show an error message to the user
      }
    },
    async getCompany() {

      const docSnap = await getDoc(doc(db, 'companies', this.compName))

      if (docSnap.exists()) {
        // assign document fields
        // to data properties
        const data = docSnap.data()
        EventBus.$emit('getCompany',data)
        this.$store.dispatch('setCurrentCompdata',data)
        console.log(data.Logo['_byteString'])
      } else {
        console.log('Document does not exist')
      }
    },
    // checkTutorial() {
    //   const Tutorial= true
    //   if(Tutorial === true) {
    //     this.tourstart()
    //   }
    // },
    // tourstart() {
    //   this.$tours.vuexyTour.start()
    // }
    checkTutorial() {
      if(this.$store.getters.getUser.tutorial === true) {
        this.$tours.vuexyTour.start()
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/tour.scss';


.btn-black {
  background-color: #000000;
  border-color: black;
  color: white;
}
.google-sign-in-button {
  cursor: pointer;
  transition: background-color .3s, box-shadow .3s;
  padding: 20px 16px; /* Adjusted padding */
  border-color: #2d3748;
  border-radius: 10px;
  color: #1a202c;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: 90px 50%; /* Adjusted background position */
  // background-color: #edf2f7;
}

.google-sign-in-button:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.google-sign-in-button:active {
  background-color: #eeeeee;
  outline: none;
  box-shadow: 
    0 -1px 0 rgba(0, 0, 0, .04),
    0 2px 4px rgba(0, 0, 0, .25),
    0 0 0 3px #c8dafc;
}


.b-button-google:hover {
  border-color: #2d3748;
  color: #1a202c;
  background-color: #edf2f7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.rounded-header {
  border-radius: 15px;
  font-size: 2.5rem; /* Adjust the size as needed */
  font-weight: bold;
  line-height: 1.2;
}


.rounded-input {
  border-radius: 10px;
}


.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
.power-effect {
  position: relative;
  text-align: center;
  font-size: 15px;
  padding: 20px;
  color: primary;
  
  transition: transform 0.2s, box-shadow 0.2s;
}

.power-effect:hover {
  transform: scale(1.05);
 
}
@import '@core/scss/vue/pages/page-auth.scss';

.table {
  width: 100%;
  border-collapse: collapse;
}

.thead-success th, .data-row td {
  padding: 8px; /* Uniform padding for headers and cells */
  text-align: left; /* Ensure left alignment */
  vertical-align: middle; /* Align vertically to middle for better appearance */
}

.thead-success th {
  background-color: #d4edda;
  font-weight: bold;
}

.data-label {
  display: none;
  font-weight: bold;
}

.button-cell .action-button {
  margin-right: 10px; /* Add space between buttons */
}

.button-cell .action-button:last-child {
  margin-right: 0; /* Remove margin from the last button */
}

@media (max-width: 768px) {
  .thead-success {
    display: none;
  }

  .data-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .data-row td {
    display: flex;
    width: 100%;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
  }

  .data-label {
    display: inline-block;
    width: 30%;
    padding-right: 10px;
  }

  .data-row td:last-child {
    border-bottom: none;
  }

  .button-cell {
    display: flex;
    justify-content: flex-start; /* Align buttons to the start */
    width: 100%;
  }
}

</style>
