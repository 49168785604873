<template>
  <!-- <b-card
    id="Payout"
    v-if="data"
    class="card-tiny-line-stats"
    body-class="pb-50"
  >
    <h6>Payout-Ratio</h6>
    <h2 class="font-weight-bolder mb-1">
     {{this.CurrentPayOut}}% -->
    <!-- </h2> -->
    <!-- chart -->
    
    <statistic-card-with-area-chart
          v-if="ordersRecevied.analyticsData"
          color="info"
          :statistic="`${this.CurrentPayOut.toFixed(2)}%`"
          statistic-title="Pay-Out-Ratio"
          :chart-data="this.series"
        />
  <!-- </b-card> -->
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { EventBus } from '@/main'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    VueApexCharts,
    StatisticCardWithAreaChart
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.$http.get('/card/card-statistics/orders')
      .then(response => { this.ordersRecevied = response.data })
  },
  methods: {
    kFormatter,
  },
  computed: {
    joke() {
      return this.$store.getters.getCurrentCompdata
    },
    series() {
      if (this.joke['PayOut-Ratio'] === undefined) {
        return this.series = {data: [0,0,0,0]} 
      }
      return this.series =
        [
          {data: Object.values(this.joke['PayOut-Ratio']).slice(-5)}
          ,
        ]
    },
    CurrentPayOut() {
      if (this.joke['PayOut-Ratio'] === undefined) {
        return this.CurrenPayout = 0 
      } 
      return this.CurrentPayOut = Object.values(this.joke['PayOut-Ratio']).slice(-1)[0]
    }
  },
  data() {
    return {
      ordersRecevied: {},
      statisticsProfit: {

        chartOptions: {
          chart: {
            redrawOnWindowResize: true,
            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
          endingShape: 'rounded',
          startingShape: "rounded",
        },},
          dataLabels: {
            enabled: false,
          },
          grid: {
            show:false,
            borderColor: $trackBgColor,
            strokeDashArray: 0,
            xaxis: {
              lines: {
                show: false,
              },
            },
            
            yaxis: {
              tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(0)}k` : val   
              },
            },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 2,
          },
          colors: [$themeColors.primary],
          
          xaxis: {
            labels: {
              show: false,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
}
</script>
