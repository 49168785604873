<template>
  <b-card title="Different Scenarios" id="Scenarios">
    <b-tabs card>
      <b-tab title="Optimistic" active>
        <b-row>
          <b-col class="mb-1">
            <!-- Optimistic scenario -->
            <b-form-group label="Growth rate in Percent" label-for="optimistic-growthrate">
              <b-form-spinbutton v-model="optimisticRate" type="number" placeholder="2" @change="fun" min="1" step="0.5" />
            </b-form-group>
            <b-form-group label="Years before Terminal Stage" label-for="optimistic-terminalrate">
              <b-form-spinbutton v-model="optimisticTerminalRate" type="number" placeholder="0" @change="fun" min="0" />
            </b-form-group>
            <b-form-group label="Discount rate in Percent" label-for="optimistic-discountrate">
              <b-form-spinbutton v-model="dRate" type="number" placeholder="0" @change="fun" min="0" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Realistic">
        <!-- Realistic scenario -->
        <b-row>
          <b-col class="mb-1">
            <b-form-group label="Growth rate in Percent" label-for="realistic-growthrate">
              <b-form-spinbutton v-model="realisticRate" type="number" placeholder="2" @change="fun" min="1" step="0.5" />
            </b-form-group>
            <b-form-group label="Years before Terminal Stage" label-for="realistic-terminalrate">
              <b-form-spinbutton v-model="realisticTerminalRate" type="number" placeholder="0" @change="fun" min="0" />
            </b-form-group>
            <b-form-group label="Discount rate in Percent" label-for="realistic-discountrate">
              <b-form-spinbutton v-model="dRateReatlistic" type="number" placeholder="0" @change="fun" min="0" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Pessimistic">
        <!-- Pessimistic scenario -->
        <b-row>
          <b-col class="mb-1">
            <b-form-group label="Growth rate in Percent" label-for="pessimistic-growthrate">
              <b-form-spinbutton v-model="pessimisticRate" type="number" placeholder="2" @change="fun" min="1" step="0.5" />
            </b-form-group>
            <b-form-group label="Years before Terminal Stage" label-for="pessimistic-terminalrate">
              <b-form-spinbutton v-model="pessimisticTerminalRate" type="number" placeholder="0" @change="fun" min="0" />
            </b-form-group>
            <b-form-group label="Discount rate in Percent" label-for="pessimistic-discountrate">
              <b-form-spinbutton v-model="dRatePessimistic" type="number" placeholder="0" @change="fun" min="0" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BFormInput, BRow, BCol, BFormGroup, BFormSpinbutton, BCard, BTabs, BTab } from 'bootstrap-vue'
import { codeBasic } from './code'
import VueApexCharts from 'vue-apexcharts'
import { EventBus } from '@/main'
import data from '@/router/routes/ui-elements'

export default {
  components: {
    BCardCode,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    VueApexCharts,
    BFormSpinbutton,
    BCard,
    BTabs,
    BTab
  },
  created() {
    this.$store.dispatch('setDiscountRate', (this.optimisticRate - this.dRate) / 100)
    this.$store.dispatch('setDiscountRateRealistic', (this.realisticRate - this.dRateReatlistic) / 100)
    this.$store.dispatch('setDiscountRatePessimistic', (this.pessimisticRate - this.dRatePessimistic) / 100)
    this.$store.dispatch('setYearsOptimistic',this.optimisticTerminalRate)
    this.$store.dispatch('setYearsRealistic',this.realisticTerminalRate)
    this.$store.dispatch('setYearsPessimistic',this.pessimisticTerminalRate)
  },
  methods: {
    fun: function () {
      this.$store.dispatch('setDiscountRate', (this.optimisticRate - this.dRate) / 100)
      this.$store.dispatch('setDiscountRateRealistic', (this.realisticRate - this.dRateReatlistic) / 100)
      this.$store.dispatch('setDiscountRatePessimistic', (this.pessimisticRate - this.dRatePessimistic) / 100)
      this.$store.dispatch('setYearsOptimistic',this.optimisticTerminalRate)
      this.$store.dispatch('setYearsRealistic',this.realisticTerminalRate)
      this.$store.dispatch('setYearsPessimistic',this.pessimisticTerminalRate)
    }
  },
  data() {
    return {
      codeBasic,
      Rate: 0,
      dRate: 3,
      dRateReatlistic: 3,
      dRatePessimistic: 3,
      optimisticRate: 0,
      realisticRate: 0,
      pessimisticRate: 0,
      optimisticTerminalRate: 10,
      realisticTerminalRate: 8,
      pessimisticTerminalRate: 7,
    }
  }
}
</script>

<style>
.b-form-spinbutton {
  border-radius: 15px; /* Set the border radius to 15px */
}
</style>