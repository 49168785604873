<template>
  <div class="container">
    <!-- Search input -->
    <div class="row mt-5">
      <div class="col-md-12">
        <b-card class="mb-4">
          <h1 class="text-center d-md-block font-weight-bold">Explore</h1>
          <h5 class="mb-2 text-center d-md-block text-secondary">Simplify your company research with real-time insights and interactive data.</h5>
          <div class="d-flex justify-content-center">
            <input type="text" v-model="searchQuery" class="form-control search-input" placeholder="Search...">
          </div>
          <!-- Suggestions display here -->
          <ul v-if="searchQuery !== ''" class="list-group suggestion-list">
            <li v-for="(company, index) in filteredCompaniesSearch" :key="index" class="list-group-item suggestion-item" @click="handleSuggestionClick(company)">
              <div class="suggestion-name">{{ company.name }}</div>
              <div class="suggestion-details">
                <span class="suggestion-ticker">{{ company.ticker }}</span>
                <span class="suggestion-sector float-right">{{ company.sector }}</span>
              </div>
            </li>
          </ul>
        </b-card>
      </div>
    </div>


    <!-- Company cards -->
    <div class="row justify-content-center mt-3">
      <div class="col-md-6" v-for="(company, index) in companies2" :key="index">
        <b-card class="rounded-card" @click="handleCardClick(company)">
          <div class="d-flex align-items-center">
            <div class="logo-container mr-3">
              <img :src="company.logo" alt="Company Logo" class="company-logo">
            </div>
            <div>
              <h5 class="card-title">{{ company.ticker }}</h5>
              <p class="card-text">{{ company.name }}</p>
            </div>
            <div class="ml-auto">
              <h5 class="card-title">${{ company.sharePrice.toFixed(2) }}</h5>
              <p class="card-text" :class="{ 'text-danger': company.priceChange < 0, 'text-success': company.priceChange >= 0 }">
                {{ company.priceChange }} ({{ (company.priceChange/company.sharePrice.toFixed(2)).toFixed(2) }}%)
              </p>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BOverlay, BButton, BCard } from 'bootstrap-vue';
import { EventBus } from '@/main'
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from '@/firebase/index';
import { getAuth } from "firebase/auth";
import searchAndBookmarkData from "./search-and-bookmark-data.js";
import axios from 'axios';

export default {
  components: {
    BCard, BOverlay,
    BButton,
  
  },
  mounted() {
},
  computed: {
    isFreeTrialValid() {
      // Check the value of FreeTrial and return true or false accordingly
      return this.$store.getters.getFreeTrial > 5;
    },
    filteredCompaniesSearch() {
      const searchTerm = this.searchQuery.toLowerCase();
      const filteredCompanies = searchAndBookmarkData.companies.data.filter(company => {
        if (company && company.name && company.ticker) {
          return (
            company.name.toLowerCase().includes(searchTerm) ||
            company.ticker.toLowerCase().includes(searchTerm)
          );
        }
        return false;
      });
      // Return only the first 5 suggestions
      // console.log(filteredCompanies)
      return filteredCompanies.slice(0, 5);
    },
    // filteredCompaniesSearch() {
    //   const searchTerm = searchQuery.toLowerCase();
    //   return companies.filter(company =>
    //     company.name.toLowerCase().includes(searchTerm) ||
    //     company.ticker.toLowerCase().includes(searchTerm)
    //   ).slice(0, 5);
    // }
  //
   },
  async created() {
    await this.fetchSharePrices();
    EventBus.$on('suggestionSelected', (suggestion) => {
      this.CheckFreeTrial();
    });

    const auth = getAuth();
    const user = await auth.currentUser.uid;
    const docRef = doc(db, "users", user);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const FreeTrial = docSnap.data().FreeTrial;
      this.$store.dispatch('setFreeTrial', FreeTrial);
    }
  },
  data() {
    return {
      searchQuery: '',
      companies: searchAndBookmarkData.companies.data,
      companies2: [
        {
          name: "Apple Inc",
          ticker: 'AAPL',
          sector: 'Technology',
          sharePrice: 150.45,
          priceChange: -2.34,
          logo: 'https://logo.clearbit.com/apple.com'
        },
        {
          name: 'Amazon.com Inc',
          ticker: 'AMZN',
          sector: 'Consumer Cyclical',
          sharePrice: 3212.21,
          priceChange: 5.67,
          logo: 'https://logo.clearbit.com/amazon.com'
        },
        {
          name: 'Microsoft Corporation',
          ticker: 'MSFT',
          sector: 'Technology',
          sharePrice: 310.15,
          priceChange: -1.45,
          logo: 'https://logo.clearbit.com/microsoft.com'
        },
        {
          name: 'Alphabet Inc Class C',
          ticker: 'GOOGL',
          sector: 'Communication Services',
          sharePrice: 2868.79,
          priceChange: 3.21,
          logo: 'https://logo.clearbit.com/abc.xyz'
        },
        {
          name: 'Meta Platforms Inc',
          ticker: 'META',
          sector: 'Communication Services',
          sharePrice: 286.96,
          priceChange: -0.89,
          logo: 'https://logo.clearbit.com/meta.com'
        },
        {
          name: 'Tesla Inc',
          ticker: 'TSLA',
          sector: 'Consumer Cyclical',
          sharePrice: 871.60,
          priceChange: 7.45,
          logo: 'https://logo.clearbit.com/tesla.com'
        },
        {
          name: 'Johnson & Johnson',
          ticker: 'JNJ',
          sector: 'Healthcare',
          sharePrice: 169.25,
          priceChange: -1.76,
          logo: 'https://logo.clearbit.com/jnj.com'
        },
        {
          name: 'Walmart Inc',
          ticker: 'WMT',
          sector: 'Consumer Defensive',
          sharePrice: 142.33,
          priceChange: 1.23,
          logo: 'https://logo.clearbit.com/walmart.com'
        },
        {
          name: 'Procter & Gamble Company',
          ticker: 'PG',
          sector: 'Consumer Defensive',
          sharePrice: 148.40,
          priceChange: -0.67,
          logo: 'https://logo.clearbit.com/pg.com'
        },
        {
          name: 'Visa Inc. Class A',
          ticker: 'V',
          sector: 'Financial Services',
          sharePrice: 234.55,
          priceChange: 2.45,
          logo: 'https://logo.clearbit.com/visa.com'
        }
      ]
    };
  },

  methods: {
    async fetchSharePrices() {
  // console.log('Fetching share prices...');
  for (const company of this.companies2) {
    try {
      const response = await axios.get(`https://eodhd.com/api/eod/${company.ticker}?filter=last_close&api_token=652527b5bf7089.92212513&fmt=json`);
      // console.log(`Response for ${company.ticker}:`, response.data);
      company.sharePrice = response.data; // Update sharePrice for the current company
    } catch (error) {
      // console.error(`Error fetching share price for ${company.ticker}:`, error);
      // Handle error if needed
    }
  }
  // console.log('Share prices fetched:', this.companies2);
},
// async fetchPriceChange() {
//       try {
//         // Get yesterday's date in MM/DD/YYYY format
//         let yesterday = this.getYesterdayAmericanDate();

//         // Construct API URL with dynamic date
//         let apiUrl = `https://eodhd.com/api/eod/MCD.US?from=${yesterday}&period=d&api_token=652527b5bf7089.92212513&fmt=json`;

//         // Fetch data from API
//         let response = await fetch(apiUrl);
//         let data = await response.json();

//         // Assuming data structure from API response
//         // Here you should adjust according to the actual API response structure
//         this.priceChange = data[0].close - data[1].close; // Example calculation, adjust as per actual data structure

//       } catch (error) {
//         console.error('Error fetching price change:', error);
//       }
//     },
//     getYesterdayAmericanDate() {
//       let today = new Date();
//       let yesterday = new Date(today);
//       yesterday.setDate(today.getDate() - 1);

//       let month = yesterday.getMonth() + 1;
//       let day = yesterday.getDate();
//       let year = yesterday.getFullYear();

//       // Padding single-digit month or day with leading zero if necessary
//       let monthStr = (month < 10) ? '0' + month : '' + month;
//       let dayStr = (day < 10) ? '0' + day : '' + day;

//       return monthStr + '/' + dayStr + '/' + year;
//     },
//     redirectToNewRoute() {
//       // Pushes a new route to the router
//       this.$router.push('/pages/pricing'); // Replace '/new-route' with the route you want to navigate to
//     },
//     async UpdateFreeTrial() {
//       const auth = getAuth();
//       const user = await auth.currentUser.uid;
//       const docRef = doc(db, "users", user);
//       const docSnap = await getDoc(docRef);
//       if (docSnap.exists()) {
//         console.log("Document data:", docSnap.data());
//         const FreeTrial = docSnap.data().FreeTrial
//         await updateDoc(doc(db, "users", user), ({ FreeTrial: FreeTrial+1 }))
       
//       }
//     },
    async CheckFreeTrial() {
        const auth = getAuth();
      const user = await auth.currentUser.uid;
      const docRef = doc(db, "users", user);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        this.$store.dispatch('setUser', docSnap.data());
        const FreeTrial = docSnap.data().FreeTrial;
        this.$store.dispatch('setFreeTrial', docSnap.data().FreeTrial);
      }
    },
    handleCardClick(cardName) {
      console.log("Click", cardName);
      // this.UpdateFreeTrial()
      EventBus.$emit('suggestionSelected', cardName);
      EventBus.$emit('tabindexx', 1);
    },
    handleSuggestionClick(company) {
      this.searchQuery = ''; // Clear search query
      // Do something with the selected company
      // this.UpdateFreeTrial()
      console.log("Selected company:", company);
      EventBus.$emit('suggestionSelected', company);
      EventBus.$emit('tabindexx', 1);
    }
  }
};
</script>

<style scoped>
.rounded-card {
  border-radius: 20px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.rounded-card:hover {
  transform: translateY(-5px);
}

.search-input {
  width: 100%;
  max-width: 400px;
  border-radius: 15px;
  padding: 10px;
}

.suggestion-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
  max-width: 400px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  z-index: 10;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.suggestion-ticker {
  margin-right: 5px;
}

.suggestion-sector {
  float: right;
  font-size: 0.9em;
}

.logo-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}

.company-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-title {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.text-danger {
  color: red;
}

.text-success {
  color: green;
}
</style>
