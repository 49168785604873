<template>
  <statistic-card-with-area-chart
    color="success"
    :statistic="`${this.CurrentBuyBack.toFixed(2)}%`"
    statistic-title="Outstanding-Shares"
    :chart-data="this.series"
  />
</template>

<script>
import { VueApexCharts } from 'vue-apexcharts'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'

export default {
  components: {
    VueApexCharts,
    StatisticCardWithAreaChart,
  },
  computed: {
    joke() {
      return this.$store.getters.getCurrentCompdata
    },
    series() {
      if (this.joke['Share-Buyback'] === undefined) {
        return [{ data: [] }]
      }
      return [
        { data: Object.values(this.joke['Share-Buyback']).slice(-5) },
      ]
    },
    CurrentBuyBack() {
      if (this.joke['Share-Buyback'] === undefined) {
        return 0
      }
      return ((Object.values(this.joke['Share-Buyback']).slice(-1)[0] - Object.values(this.joke['Share-Buyback']).slice(-2)[0]) / Object.values(this.joke['Share-Buyback']).slice(-2)[0]) * 100
    },
    AverageBuyBack() {
      if (this.joke['Share-Buyback'] === undefined) {
        return 0
      }
      return Object.values(this.joke['Share-Buyback']).reduce((a, b) => a + b, 0) / Object.values(this.joke['Share-Buyback']).length
    }
  },
  data() {
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'area',
            toolbar: {
              show: false,
            },
            redrawOnParentResize: true,
          },
          plotOptions: {},
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          stroke: { width: 3, curve: 'smooth' },
          colors: ['#28c76f'],
          grid: {
            show: false,
          },
          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            enabled: true,
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
            },
          },
        },
      },
    }
  },
}
</script>