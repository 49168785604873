<template>
    <div>
      <b-modal
        id="modal-default"
        centered
        title="Default Modal"
        show="true"
      >
        <b-card-text>Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly.</b-card-text>
      </b-modal>
    </div>
  </template>
  
  <script>
  import { BButton, BModal, VBModal } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  

  export default {
    components: {
      BButton,
      BModal,

    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },
  }
  </script>
   