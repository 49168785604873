<template>
 
  <b-card :v-if="user">
    <!-- <b-overlay
      
      rounded="sm"
    > -->
    <b-tabs class="d-none d-md-block dark"
    pills
    v-model="tabIndex">
      <b-tab lazy
      pills
      
      >
        <template #title>
          <feather-icon icon="SearchIcon" />
          <span>Search</span>
        </template>
          
              <!-- <b-modal
            ref="my-modal"
            hide-footer
            centered
            title="Search a company"
          >
            <div class="d-block text-center">
              <search-bar-vue />
            </div>
          </b-modal> -->
         
          	<SearchVue />
      </b-tab>
      <b-tab  class="tab-pill" lazy :disabled="isDataEmpty">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span  class="tab-pill">Home</span>
        </template>
        <access-control-vue />
      </b-tab>
      <b-tab lazy
      :disabled="isDataEmpty">
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>Financial Strength</span>
        </template>

        <financials-vue />

      </b-tab>
      <b-tab lazy
      :disabled="isDataEmpty">
        <template #title>
          <feather-icon icon="BarChartIcon" />
          <span>Growth</span>
        </template>

        <growth-vue />
        
      </b-tab>
      <b-tab lazy
      :disabled="isDataEmpty">
        <template #title>
          <feather-icon icon="PieChartIcon" />
          <span>Profitability</span>
        </template>

       <profitability-vue />

      </b-tab>

      <b-tab lazy
      :disabled="isDataEmpty">
        <template #title>
          <feather-icon icon="DollarSignIcon" />
          <span>Valuation</span>
        </template>

       <valuation-vue />

      </b-tab>
      <b-tab lazy
      :disabled="isDataEmpty">
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Statements</span>
        </template>

       <StatementTabsVue />

      </b-tab>
    </b-tabs>
    <b-card>
      <b-collapse v-model="accordionVisible" class="d-md-none">
      <b-card v-for="(tab, index) in tabs" :key="index">
        <!-- <b-button block variant="outline-primary" class="mb-2"  v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="toggleAccordion(index)">{{ tab.title }}</b-button> -->
        <b-collapse v-model="tab.open">
          <!-- Use the same components as tabs -->
          <component :is="tab.component" v-if="tab.open" />
        </b-collapse>
      </b-card>
    </b-collapse>
    </b-card>
    <template #overlay>
          <div class="text-center">
            <feather-icon
              icon="ClockIcon"
              size="24"
            />
            <b-card-text id="cancel-label">
              Please wait...
            </b-card-text>
            <b-button
              ref="cancel"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              size="sm"
              aria-describedby="cancel-label"
              @click="show = false"
            >
              Cancel
            </b-button>
          </div>
        </template>
  <!-- </b-overlay> -->
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BTabs, BTab, BCardText,BModal,BCollapse,BButton, BOverlay } from 'bootstrap-vue'
import AccessControlVue from '../acl/AccessControl.vue'
import financialsVue from '../financials/financials.vue'
import GrowthVue from '../growth/Growth.vue'
import profitabilityVue from '../profitability/profitability.vue'
import valuationVue from '../valuation/valuation.vue'
import { doc, getDoc, docRef,docSnap } from "firebase/firestore";
import { getAuth  } from "firebase/auth";
import { db } from '@/firebase/index'
import Ripple from 'vue-ripple-directive'
import SearchBarVue from '../Search/SearchBar.vue'
import { EventBus } from '@/main'
import _ from 'lodash';
import StatementTabsVue from '../Statements/StatementTabs.vue'
import SearchVue from './Search.vue'


export default {
  components: {
    BCardCode,
    BCardText,
    BTabs,
    BTab,
    AccessControlVue,
    financialsVue,
    GrowthVue,
    profitabilityVue,
    valuationVue,
    BModal,
    SearchBarVue,
    StatementTabsVue,
    SearchVue,
    BCollapse,
    BButton,
    BOverlay
  },
  directives: {
    Ripple,
  }, 
  data() {
      return {
        tabIndex: 1,
        show: false,
        accordionVisible: true,
      tabs: [
        { title: 'Search', component: 'SearchVue', open: true }, // Assuming 'SearchVue' is your component name
        { title: 'Home', component: 'AccessControlVue', open: true },
        { title: 'Financial Strength', component: 'financialsVue', open: true }, // Assuming 'SearchVue' is your component name
        { title: 'Growth', component: 'GrowthVue', open: true },
        { title: 'Profitability', component: 'profitabilityVue', open: true }, // Assuming 'SearchVue' is your component name
        { title: 'Valuation', component: 'valuationVue', open: true },
      ]
      }
  },
  async created() {
      EventBus.$on('suggestionSelected',  (suggestion) => {
        this.CheckFreeTrial()
      });
       // Überprüfen Sie, ob die erstellte Hook-Funktion ausgeführt wird
      EventBus.$on('tabindexx',  (data) => {
        console.log("Tabindexx event received"); // Überprüfen Sie, ob das Event empfangen wird
        this.tabIndex = data
        // this.$refs['my-modal'].hide()
        this.CheckFreeTrial();
      });
    const auth = getAuth();
    const user =  await auth.currentUser.uid;
    const docRef = doc(db, "users", user);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      this.$store.dispatch('setUser',docSnap.data())
      
      const FreeTrial = docSnap.data().FreeTrial
      if (FreeTrial > 5 ){
        this.isFreeTrialValid = true
      }
    }
    this.checkTutorial()
  },
  methods: {
    async CheckFreeTrial() {
      const auth = getAuth();
      const user =  await auth.currentUser.uid;
      const docRef = doc(db, "users", user);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        this.$store.dispatch('setUser',docSnap.data())     
        const FreeTrial = docSnap.data().FreeTrial
        this.$store.dispatch('setFreeTrial', docSnap.data().FreeTrial)
      }
    },
    toggleAccordion(index) {
      this.tabs.forEach((tab, i) => {
        if (i === index) {
          tab.open = !tab.open;
        } else {
          tab.open = false; // Close other accordion sections
        }
      });
    },
    // showModal() {
    //   this.$refs['my-modal'].show()
    // },
    // hideModal() {
    //   this.$refs['my-modal'].hide()
    // },
    // toggleModal() {
    //   // We pass the ID of the button that we want to return focus to
    //   // when the modal has hidden
    //   this.$refs['my-modal'].toggle('#toggle-btn')
    // },
    checkTutorial() {
      if(this.$store.getters.getUser.tutorial === true) {
        this.$tours.vuexyTour.start()
      }
    },
  },
  computed: {
    User(){
    return  this.user = this.$store.getters.getUser
    }, 
    CurrentCompdata() {
      // Assuming this is how you're computing CurrentCompdata in Vuex
      return this.$store.state.CurrentCompdata;
    },
    isDataEmpty() {
      // Check if CurrentCompdata is empty or if getFreeTrial is greater than 5
      return Object.keys(this.CurrentCompdata).length === 0 
    },
    isFreeTrialValid() {
      // Überwache den Wert von FreeTrial und gib entsprechend true oder false zurück
      return this.$store.getters.getFreeTrial >5;
    },
},

  async mounted() {
    this.checkTutorial()
    this.$emit('elementReady', 'SearchTab');
    const auth = getAuth();
    const user =  await auth.currentUser.uid;
    const docRef = doc(db, "users", user);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      this.$store.dispatch('setUser',docSnap.data())
      console.log(docSnap.data())
      const FreeTrial = docSnap.data().FreeTrial
      if (FreeTrial > 5){
        this.isFreeTrialValid = true
      }
    }
  }
}
</script>

<style scoped>
/* Add custom styling for the accordion */
.b-card {
  margin-bottom: 10px;
  border: none; /* Remove the border */
  border-radius: 8px; /* Add some border radius */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}
.b-button {
  border-radius: 8px; /* Add some border radius */
  background-color: #FFFFFF; /* Light gray background */
  color: #007aff; /* Blue text color, typical iOS color */
  font-weight: bold;
  padding: 15px; /* Increase padding for better touch interaction */
  text-align: left; /* Align text to the left */
  border: none; /* Remove the border */
  margin-bottom: 50px; /* Add some space between buttons */
  margin-right: 50px; /* Add margin to the right of each button */
}

.b-button:hover {
  background-color: #eaeaea; /* Light gray background on hover */
}
.b-collapse {
  border-top: none; /* Remove the top border */
}
tab-pill {
  border-radius: 20px; /* Adjust the border-radius to make it more or less rounded */
  background-color: #f0f0f0; /* Background color of the pill */
  padding: 5px 15px; /* Adjust padding as needed */
  margin: 0 5px; /* Adjust margin as needed */
}


.b-nav-tabs .nav-link.active {
  background-color: #007bff !important; /* Hintergrundfarbe des aktiven Tabs-Buttons */
  color: #fff !important; /* Textfarbe des aktiven Tabs-Buttons */
  border-color: #007bff !important; /* Randfarbe des aktiven Tabs-Buttons */
  border-radius: 15px !important; /* Ändern Sie den Randradius des aktiven Tabs-Buttons */
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff; /* Color for active/inactive tabs */
    background-color: #007bff; /* Background color for active tabs */
    border-color: #007bff; /* Border color for active tabs */
}

</style>