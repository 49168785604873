<template>
   <div>
    <app-tour :steps="steps"/>
       <b-row class="match-height"> 
            <b-col lg="7">
                <apex-line-chart-vue
                />
            </b-col>
            <b-col lg="5">
                <card-text-alignment-vue />
                <form-input-basic-vue />

            </b-col>
       </b-row>
       <b-row>
        <!-- <b-col>
            <simple-valuation-vue />
        </b-col> -->
       </b-row>
       
       
   </div>
</template>


<script>
import {   BRow, BCol, BCard } from 'bootstrap-vue'
import ApexLineChartVue from './ApexLineChart.vue'
import CardTextAlignmentVue from './CardTextAlignment.vue'
import FormInputBasicVue from './FormInputBasic.vue'
import SimpleValuationVue from './SimpleValuation.vue'
import AppTour from '@core/components/app-tour/AppTour.vue'
import Ripple from 'vue-ripple-directive'



export default {
    components: {
        BRow,
        BCol,
        BCard,
        ApexLineChartVue,
        CardTextAlignmentVue,
        FormInputBasicVue,
        SimpleValuationVue,
        AppTour
    },
    directives: {
    Ripple,
  },
  data () {
        return {
          steps: [
            {
              target: '#DCF',
              header: {
                title: 'Science of Valuation',
              },
              content: 'This Graph shows value of Future Free Cashflows per share with the given parametes of no growth and a discount rate of 3%',
            },
            {
              target: '#Alert',
              header: {
                title: 'Overvalued ?',
              },
              content: 'This colored field tells you if the companies is over or undervalued given the parametes that you put in the calculation.',
            },
            {
              target: '#Inputs',
              header: {
                title: 'Growth and discount rates',
              },
              content: 'You can always change the growth and discount rates. Graph and the coloredfield will change according to your different inputs. Keep in mind that a growth rate higher than the discount rate will cause problems',
            },
            {
              target: '#card-text-alignment',
              header: {
                title: 'Questions ?',
              },
              content: 'If you have questions about the dicounted cashflow methond you can read an article about it any time, by clicking this button',
            },
            {
              target: '#card-text-alignment',
              header: {
                title: 'Finished !',
              },
              content: 'Nice! Now you know how to analyze companies with Ophelio. You can now click on the top of the screen to search for specific companies or you can head to the screener section in the sidebar to see the screener provided by Ophelio',
            }
          ],
        }
    },   
  mounted() {
    this.checkTutorial()
  },
  methods: {
    checkTutorial() {
      if(this.$store.getters.getUser.Tutorial === true) {
        this.$tours.vuexyTour.start()
      }
    },
  }
}



</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/tour.scss';
</style>
