<template>
  <div>
    <b-card>
      <table v-if="companytable && categories.length" class="table table-bordered">
        <thead>
          <tr>
            <th></th>
            <th v-for="(date, index) in slicedDates" :key="index" class="bold-and-larger">{{ date }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(category, key) in categories" :key="key">
            <td class="bold-and-larger">{{ category }}</td>
            <td v-for="(date, index) in slicedDates" :key="index">{{ getCompanyValue(category, date) }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        Loading data...
      </div>

      <!-- Bootstrap Pagination -->
      <nav v-if="paginationNeeded">
        <ul class="pagination">
          <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
            <a class="page-link" @click="prevPage" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page" :class="{ 'active': currentPage === page }">
            <a class="page-link" @click="goToPage(page)">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
            <a class="page-link" @click="nextPage" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
       <!-- Button zum Exportieren der Daten als CSV -->
       <button @click="exportDataToCsv" class="btn btn-primary">Export Cashflow Statement</button>
       <b-modal
      class="rounded-header text-title"
      id="modal-login3"
      cancel-variant="outline-danger"
      ok-variant="info"
      ok-title="Login/Register"
      cancel-title="Close"
      centered
      title="You need to Be Signed up for access"
      size="md"
      @ok="firelogin()"
      @cancel="handleCancel()"
      @hide="handleCancel()"
    >
      <b-form>
        <b-form-group class="rounded-header" >
          <b-form-input
            id="email"
            type="email"
            placeholder="Email Address"
            class="rounded-input"
            v-model="userEmail"
          />
        </b-form-group>
        <b-form-group class="rounded-header" >
          <b-form-input
            id="password"
            type="password"
            placeholder="Password"
            class="rounded-input"
            v-model="password"
          />
        </b-form-group>
        <div class="text-center">
        <hr>
        <span class="">OR</span>
        <hr >
      </div>

        <button type="button" class="google-sign-in-button btn btn-outline-secondary text-center btn-block" @click="GoogleSignIn">
              Sign In with Google
            </button>
      </b-form>
    </b-modal>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BCard, BCardText, BLink, BButton, BImg, BModal, VBModal,BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { getAuth,onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, updateDoc } from "firebase/firestore"; 
import { db } from '@/firebase/index'
import {  snapshot, getDoc, documentId,query,collection,where, getDocs, arrayRemove, FieldValue,deleteField } from "firebase/firestore";
import {  signInWithEmailAndPassword,GoogleAuthProvider,createUserWithEmailAndPassword , signInWithPopup  } from "firebase/auth";
import useJwt from '@/auth/jwt/useJwt'


// Your nFormatter function
function nFormatter(num) {
  if (num < 0) {
    // If the number is negative, apply formatting to its absolute value and add a minus sign
    num = Math.abs(num);
    if (num >= 1000000000) {
      return '-' + (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
      return '-' + (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return '-' + (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return '-' + num;
  } else {
    // If the number is non-negative, use the original formatting logic
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num;
  }
}

export default {
  components: {
    BCard, BCardText, BLink, BButton, BImg, BModal, VBModal,BForm, BFormInput, BFormGroup,
  },
  data() {
    return {
      sortedDates: [],
      itemsPerPage: 7,
      currentPage: 1,
    };
  },
  computed: {
    companytable() {
      return this.companytable = this.$store.getters.getCurrentCompdata.CashflowStatement;
    },
    categories() {
      return this.companytable ? Object.keys(this.companytable) : [];
    },
    slicedDates() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.sortedDates.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.sortedDates.length / this.itemsPerPage);
    },
    paginationNeeded() {
      return this.totalPages > 1;
    },
    name() { 
      return this.name = this.$store.getters.getCurrentCompdata.Ticker
    },
  },
  methods: {
    async firelogin() {
  console.log('FIIIIIIIIIIRE');
  const auth = getAuth();

  // Lokale Kopien der Anmeldedaten speichern
  const email = this.userEmail;
  const password = this.password;
  // Generiere den Benutzernamen aus der E-Mail-Adresse
  const username = email.split('@')[0];

  try {
    // Versuche den Benutzer anzumelden
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Überprüfe den Authentifizierungszustand
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log(user.uid);
        const uid = user.uid;
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data().userData;
          localStorage.setItem('userData', JSON.stringify(userData));
          this.$ability.update(userData.ability);
          this.$bvModal.hide('modal-login2');
          window.location.reload(); // Seite neu laden
        } else {
          console.log("No such document!");
        }
      }
    });
  } catch (error) {
    // Wenn Benutzer nicht existiert, erstelle einen neuen Benutzer
    console.log('User nicht vorhanden, wird jetzt registriert');

    try {
      // Überprüfe, ob `email` und `password` definiert sind
      if (!email || !password) {
        console.error('Fehlende Eingabedaten:', {
          username: username,
          email: email,
          password: password
        });
        throw new Error('Username, Email oder Passwort ist nicht definiert');
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Speichere die Benutzerdaten in der Firestore-Datenbank
      await setDoc(doc(db, "users", user.uid), {
        username: username,
        email: email,
        tutorial: false,
        role: "user",
        userData: {
          id: 2,
          fullName: username,
          username: username,
          password: 'client',
          avatar: '',
          email: email,
          role: 'client',
          ability: [
            { action: 'read', subject: 'ACL' },
            { action: 'read', subject: 'Auth' },
          ],
        },
      });

      // Registrierung erfolgreich, führe den Rest aus
      const response = await useJwt.register({
        username: username,
        email: email,
        password: password,
      });

      useJwt.setToken(response.data.accessToken);
      useJwt.setRefreshToken(response.data.refreshToken);
      localStorage.setItem('userData', JSON.stringify(response.data.userData));
      this.$ability.update(response.data.userData.ability);
      this.$router.push('/companyanalysis');
      window.location.reload(); // Seite neu laden

    } catch (registrationError) {
      console.error("Fehler bei der Registrierung: ", registrationError);
    }
  }
},
async GoogleSignIn() {
      const auth = getAuth();
  const provider = new GoogleAuthProvider();

  // Prompt user to select Google account for sign-in
  signInWithPopup(auth, provider)
    .then((result) => {
      // The signed-in user info
      const user = result.user;

      // Check if user document already exists
      const userDocRef = doc(db, "users", user.uid);
      getDoc(userDocRef)
        .then((docSnapshot) => {
          if (!docSnapshot.exists()) {
            // Store user details in Firestore
            setDoc(userDocRef, {
              username: user.displayName,
              email: user.email,
              tutorial: false,
              FreeTrial: 0,
              role: "user",
              userData: {
                id: 2,
                fullName: user.displayName,
                username: user.displayName,
                password: 'client',
                avatar: '', // You can update this with user's profile picture if available
                email: user.email,
                role: 'client',
                ability: [
                  {
                    action: 'read',
                    subject: 'ACL',
                  },
                  {
                    action: 'read',
                    subject: 'Auth',
                  },
                ],
              },
            });
          }

          // Proceed with registration
          useJwt.register({
            username: user.displayName,
            email: user.email,
            password: 'dummyPassword', // Dummy password?
          })
          .then(response => {
            // Handle successful registration
            useJwt.setToken(response.data.accessToken)
            useJwt.setRefreshToken(response.data.refreshToken)
            localStorage.setItem('userData', JSON.stringify(response.data.userData))
            this.$ability.update(response.data.userData.ability)
            this.$bvModal.hide('modal-login3');
            window.location.reload();
            // Move navigation here
          })
          .catch(error => {
            // Handle registration errors
            console.error("Error during registration:", error);
          });
        })
        .catch((error) => {
          // Handle Firestore query error
          console.error("Error querying Firestore:", error);
        });
    })
    .catch((error) => {
      // Handle errors here
      console.error("Error during Google registration:", error);
    });
  },
    logCompanyTable() {
      console.log(typeof this.companytable);
    },
    exportDataToCsv()  {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        // If user is not authenticated, show the login modal
        this.$bvModal.show('modal-login3')
        console.log('Now User')
        return; // Exit the method to prevent further execution
      } else {
        const data = this.companytable;
      const worksheetName = 'BalanceSheet';
      const uri = 'data:application/vnd.ms-excel;base64,';
      const template = `
        <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
          <head>
            <!--[if gte mso 9]>
              <xml>
                <x:ExcelWorkbook>
                  <x:ExcelWorksheets>
                    <x:ExcelWorksheet>
                      <x:Name>${worksheetName}</x:Name>
                      <x:WorksheetOptions>
                        <x:DisplayGridlines/>
                      </x:WorksheetOptions>
                    </x:ExcelWorksheet>
                  </x:ExcelWorksheets>
                </x:ExcelWorkbook>
              </xml>
            <![endif]-->
          </head>
          <body>
            ${this.createTableForExport(data)}
          </body>
        </html>
      `;
      const base64 = s => window.btoa(unescape(encodeURIComponent(s)));
      const blob = new Blob([template], { type: 'application/vnd.ms-excel' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${this.name}_balancesheet.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      }
      
    },

    createTableForExport(data) {
      let table = '<table>';
      const keys = Object.keys(data);
      const columns = Object.keys(data[keys[0]]);

      // Create header row
      table += '<tr><th></th>';
      columns.forEach(column => {
        table += `<th>${column}</th>`;
      });
      table += '</tr>';

      // Create data rows
      keys.forEach(key => {
        table += `<tr><td>${key}</td>`;
        columns.forEach(column => {
          table += `<td>${data[key][column]}</td>`;
        });
        table += '</tr>';
      });

      table += '</table>';
      return table;
    },
    getFirstCategoryValues() {
      return this.companytable[Object.keys(this.companytable)[0]] || {};
    },
    getCompanyValue(category, date) {
      return this.companytable[category] ? nFormatter(this.companytable[category][date]) : '';
    },
    sortDates() {
      this.sortedDates = Object.keys(this.getFirstCategoryValues()).sort((a, b) => new Date(b) - new Date(a));
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    prevPage() {
      this.goToPage(this.currentPage - 1);
    },
    nextPage() {
      this.goToPage(this.currentPage + 1);
    },
  },
  created() {
    this.sortDates();
  },
};
</script>

<style scoped>
/* Add your styles here if needed */
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .bold-and-larger {
    font-weight: bold;
    font-size: 1.1em;
  }
</style>
